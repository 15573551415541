<template>
  <div class="info">
    <div class="infoTop">
      <div class="info-title info-top-position">
          企业基本信息统计
      </div>
      <div class="info-content" >
        <div class="project-num mb">
            <div class="project-title">
                正在进行中的项目（个）
            </div>
            <div class="project-num-box">
                <div class="num"  v-for="(item,index) in getProjectNum" :key="index">
                    {{item}}
                </div>
            </div>
        </div>
        <div class="arrears-amount mb" >
            <div class="arrears-amount-title">
                拖欠总金额（元）
            </div>
            <div class="arrears-amount-num txt-style">
                {{companyInfo.arrearsMoney}}
            </div>
        </div>
        <div class='people-box'>
            <div class="txt1">
                涉及
            </div>
            <div class="num1 txt-style">
                {{companyInfo.teamNum}}
            </div>
            <div class="txt2">
                名领队
            </div>
             <div class="num2 txt-style">
                 {{companyInfo.groupNum}}
            </div>
            <div class="txt3">
                个班组
            </div>
            <div class="num3 txt-style">
                {{companyInfo.workerNum}}
            </div>
            <div class="txt4">
                名工人
            </div>
        </div>
      </div>
      <div id="chartTop" class="chart-top">
      </div>
    </div>
    <div class="info-bottom">
     <div class="info-title info-bottom-position">
        异常项目统计
      </div>
      <div id="chartBottom" class="chart-bottom"> 
      </div>
      <div class="scale-box scale-position1">
        <scaleChart 
        ref="createEchart1"
        :id="'Echart1'"
        :color="'#ed7048'" 
        :shadowColor="'#ed7048'"
        :bgColor="'#121a32'"
        :size="size.scale.size"
        :name="defaultProject[0].name"
        >
        </scaleChart>
      </div>
      <div  class="scale-box scale-position2">
        <scaleChart 
        ref="createEchart2"
        :id="'Echart2'" 
        :color="'#e9ad3a'" 
        :shadowColor="'#e9ad3a'"
        :bgColor="'#121a32'"
        :size="size.scale.size"
        :name="defaultProject[1].name"
         >
        </scaleChart>
      </div>
      <div class="scale-box scale-position3">
        <scaleChart 
        ref="createEchart3"
        :id="'Echart3'"
        :color="'#fcff00'" 
        :shadowColor="'#fcff00'"
        :bgColor="'#121a32'"
        :size="size.scale.size"
        :name="defaultProject[2].name"
        >
        </scaleChart>
      </div>
    </div>
  </div>
</template>
<script>
import  'echarts-liquidfill'
import scaleChart from '../../../components/scaleChart'
export default {
  name:"info",
  components:{
      scaleChart,
  },
  props:{ 
    // companyInfo:{
    //    type:Object
    // },
    // abnormalProjectInfo:{
    //     type:Object
    // }
  },
  watch:{
  },
  data(){
    return{
        companyInfo:{
            abnormalProject: 0,
            arrearsMoney: 0,
            groupNum: 0,
            normalProject: 0,
            progressProject: 0,
            teamNum: 0,
            workerNum: 0,
        },
        abnormalProjectInfo:{
            arrearsNum: 0,
            redNum: 0,
            yellowNum: 0
        },
        pieData: [
            {value: 0, name: '正常项目', },
            {value: 0, name: '非正常项目'},
        ],
        defaultProject:[
            // 拖欠工人项目 红色
            // 半拖欠项目 橙色
            // 拖欠劳务公司项目 黄色
            {value: 0, name: '拖欠工人项目',enName:"arrearsNum",color:"#ed7048" },
            {value: 0, name: '半拖欠项目',enName:"redNum", color:"#e9ad3a"},
            {value: 0, name: '拖欠劳务公司',enName:"yellowNum", color:"#fcff00"},
        ],
        projectNum:[  // 正在进行中的项目（个）
            0,0,0,6
        ],
        // arrearsNum:"100000.00", // 拖欠总金额（元）
        getProjectNum:[0],
        page2:0.2,
        maxAll:0,
        windowX:"",
        s:{
            chartTop:{
               name:12, 
               percent:16, 
               value:16, 
               g:12, 
            },
            labelLine:{  //企业基本信息统计饼图的折线长度
                length: 8,
                length2: 12,
                show: true,
                align: 'left'   
            },
            chartBottom:{
                name:10,
                percent:16,
                center:["50%","35%"]
            },
            scale:{
                size:12
            }
        },
        l:{
            chartTop:{
               name:16, 
               percent:22,  //控制上饼图
               value:22, 
               g:16, 
            },
            labelLine: {
                length: 30,
                length2: 45,
                show: true,
                align: 'left'
            },
            chartBottom:{
                name:16,
                percent:28,
                center:["50%","33%"], //控制下饼图
            },
            scale:{
                size:18
            }
        },
        size:{
        }
    }
  },
  created(){
    this.getWidth()
  },
  mounted(){
  },
  methods:{
    getWidth(){
        this.windowX = document.documentElement.clientWidth
        console.log(this.windowX)
        if(this.windowX<=1024){
            console.log("11,小于等于1024")
            this.size = this.s
        }else if(this.windowX >1024){
            console.log("22")
            this.size = this.l
        }
    },
    drawPieTop() {
        let _this = this
            let data = [];
            let typeArr = []
            let color = ['#43ccfd','#ed7048']
            typeArr.push( _this.pieData[0].value)
            typeArr.push( _this.pieData[1].value)
            for (let i = 0; i < _this.pieData.length; i++) {
                if(typeArr[0]==0 || typeArr[1]==0){
                    data.push({
                    value: _this.pieData[i].value,
                    name: _this.pieData[i].name,
                    itemStyle: {
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 10,
                        },
                        normal: {
                            borderWidth: 0,
                            shadowBlur: 15,
                            borderColor: color[i],
                            shadowColor: color[i]
                        }
                    }
                },);
                }else{
                    data.push({
                        value: _this.pieData[i].value,
                        name: _this.pieData[i].name,
                        itemStyle: {
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 10,
                            },
                            normal: {
                                borderWidth: 0,
                                shadowBlur: 15,
                                borderColor: color[i],
                                shadowColor: color[i]
                            }
                        }
                    },
                    {
                        value: this.page2,
                        name: '',
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                },
                                color: 'rgba(0, 0, 0, 0)',
                                borderColor: 'rgba(0, 0, 0, 0)',
                                borderWidth: 0
                            }
                        }
                    });
                }
            }
        this.charts = this.$echarts.init(document.getElementById("chartTop"))
        this.charts.setOption({
          // 用例
            // backgroundColor: '#0A2E5D',
            color: color,
            title: {
                text: '',
                top: '48%',
                textAlign: "center",
                left: "49%",
            },
            graphic: {
                elements: [{
                    type: "image",
                    z: 3,
                    style: {
                       width: 178,
                        height: 178
                    },
                    left: 'center',
                    top: 'center',
                    position: [100, 100]
                }]
            },
            tooltip: {
                show: false
            },
            toolbox: {
                show: false
            },
          series: [{
            type: 'pie',
            clockWise: false,
            hoverAnimation: false,
            radius: ['25%', '20%'],
            center: ['50%', '42%'],
            roseType: 'radius',
            avoidLabelOverlap: false,
            itemStyle: {
            normal: {
                label: {
                    formatter: function(params) {
                        let percent = 0;
                        let total = 0;
                        for (let i = 0; i < _this.pieData.length; i++) {
                            total += _this.pieData[i].value;
                        }
                        percent = ((params.value / total) * 100).toFixed(0);
                        if(isNaN(percent)){
                            percent=0
                        }
                        if (params.name !== '') {
                            return  '{name|'+ params.name+'}'+ '\n{percent|' +percent+ '%}' + '\n' + '{value|'+ params.value + '}' + '{g|(个)}'
                        } else {
                            return '';
                        }
                    },
                    rich:{
                        name: {
                            color: '#58d2ff',
                            fontSize: _this.size.chartTop.name,
                            fontWeight: 400,
                            padding: [5, 0, 0, 5]
                        },
                        percent:{
                            color: '#d0fffd',
                            fontSize:  _this.size.chartTop.percent,
                            fontWeight: 800,
                            padding: [5, 0, 0, 5] 
                        },
                        value:{
                            color: '#58d2ff',
                            fontSize:  _this.size.chartTop.value,
                            fontWeight: 800,
                            padding: [5, 0, 0, 5] 
                        },
                        g:{
                            color: '#58d2ff',
                            fontSize:  _this.size.chartTop.g,
                            fontWeight: 400,
                            padding: [5, 0, 0, 5]  
                        }
            
                    },
                },
                labelLine: this.size.labelLine
            }
            },
            data:data // 数据来源
            }]
        })
   
    },
    drawPieBottom() {
        let _this = this
            let data = [];
            let color = ['#ed7048', '#e9ad3a','#fcff00']
            for (let i = 0; i < _this.defaultProject.length; i++) {
                data.push({
                    value: _this.defaultProject[i].value,
                    name: _this.defaultProject[i].name,
                    itemStyle: {
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 10,
                        },
                        normal: {
                            borderWidth: 0,
                            shadowBlur: 15,
                            borderColor: color[i],
                            shadowColor: color[i]
                        }
                    }
                },

                );
            }
        this.charts = this.$echarts.init(document.getElementById("chartBottom"))
        this.charts.setOption({
          // 用例
            // backgroundColor: '#0A2E5D',
            color: color,
            title: {
                text: '',
                top: '48%',
                textAlign: "center",
                left: "49%",
            },
            graphic: {
                elements: [{
                    type: "image",
                    z: 3,
                    style: {
                        width: 178,
                        height: 178
                    },
                    left: 'center',
                    top: 'center',
                    position: [100, 100]
                }]
            },
            tooltip: {
                show: false
            },
            toolbox: {
                show: false
            },
          series: [{
            // name: '半径模式',
            type: 'pie',
            clockWise: false,
            radius : '30%',
            center: _this.size.chartBottom.center,
            roseType: 'radius',
            itemStyle: {
            normal: {
                label: {
                    formatter: function(params) {
                        let percent = 0;
                        let total = 0;
                        for (let i = 0; i < _this.defaultProject.length; i++) {
                            total += _this.defaultProject[i].value;
                        }
                        percent = ((params.value / total) * 100).toFixed(0);
                        if(isNaN(percent)){
                           percent=0 
                        }
                        if (params.name !== '') {
                            return  '{name|'+ params.name+'}'+ '\n{percent|' +percent+ '%}' 
                        } else {
                            return '';
                        }
                    },
                    rich:{
                        name: {
                            color: '#58d2ff',
                            fontSize: _this.size.chartBottom.name,
                            fontWeight: 400,
                            padding: [5, 0, 0, 5]
                        },
                        percent:{
                            color: '#d0fffd',
                            fontSize: _this.size.chartBottom.percent,
                            fontWeight: 800,
                            padding: [5, 0, 0, 5] 
                        },
               
            
                    },
                },
                labelLine: {  // 异常数据折线
                    length: 8,
                    length2: 12,
                    show: true,
                    align: 'left'
                }
            }
            },
            data: data // 数据来源
            }]
        })
    },

    //设置
    initData(data){
        console.log(data,'companyInfo')
        // 企业基本信息项目数据转换
        this.companyInfo = data
        this.pieData[0].value = this.companyInfo.normalProject 
        this.pieData[1].value = this.companyInfo.abnormalProject
         this.page2 = (this.pieData[0].value + this.pieData[1].value) /50
        if(this.companyInfo.progressProject){
            this.getProjectNum = (this.companyInfo.progressProject+"").split("")
        }else{
            this.getProjectNum = [0]
        }

        this.drawPieTop()

    },
    initData2(data){
        console.log(data,'abnormalProjectInfo')
        //异常项目统计数据 格式转换
        this.abnormalProjectInfo = data
        this.defaultProject[1].value = this.abnormalProjectInfo.arrearsNum || 0 // 半拖欠项目
        this.defaultProject[0].value = this.abnormalProjectInfo.redNum || 0  // 拖欠工人项目
        this.defaultProject[2].value = this.abnormalProjectInfo.yellowNum || 0  //拖欠劳务公司项目
        this.maxAll = this.defaultProject[0].value + this.defaultProject[1].value + this.defaultProject[2].value || 0
        this.drawPieBottom()
        this.$refs.createEchart1.createEchart(this.maxAll,this.defaultProject[0].value)
        this.$refs.createEchart2.createEchart(this.maxAll,this.defaultProject[1].value)
        this.$refs.createEchart3.createEchart(this.maxAll,this.defaultProject[2].value)
    }
  },

    
}
</script>
<style scoped lang="less"> 
.infoTop{
    position: relative;
    width: 100%;
    height: 5rem;
}
 .info-title{
    color: #58d2ff;
    font-size: 0.2rem;
}
 .info-top-position{
    position: absolute;
    top: 0.22rem;
    left: 0.12rem;
}
 .info-content{
    position: absolute;
    left: 0.12rem;
    top: 2.5rem;
    width: 100%;
}
 .project-num{
}
 .info-content{
    color: #58d2ff;
    text-align: left;
}
 .project-num{
    display:flex;
     .project-title{
        width: 1.8rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
        color: #58D2FF;
    }
     .project-num-box{
        width: 2rem;
        display:flex;
         .num{
            width: 0.39rem;
            height: 0.68rem;
            background: url("../../../assets/screen/objectNum.png") no-repeat 0 0;
            background-size: 100% 100%;
            line-height: 0.68rem;
            text-align: center;
            font-size: 0.3rem;
            color: #D0FFFD;
            margin-right: 0.08rem;
        }
    }
}
 .arrears-amount{
     display:flex;
     .arrears-amount-title{
        width: 1.5rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
        color: #58D2FF;
    }
     .arrears-amount-num{
        width: 2.12rem;
        height: 0.68rem;
        background: url("../../../assets/screen/numberBg.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
}
 .people-box{
    display:flex;
     .txt1{
        width: 0.32rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
    }
     .txt2{
        width: 0.5rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
    }
     .txt3{
        width: 0.5rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
    }
     .txt4{
        width: 0.5rem;
        line-height: 0.68rem;
        font-size: 0.16rem;
    }
     .num1{
        width: 0.63rem;
        height: 0.68rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
     .num2{
        width: 0.63rem;
        height: 0.68rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
     .num3{
        width: 0.63rem;
        height: 0.68rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
}
 .mb{
    margin-bottom: 0.1rem;
}
 .txt-style{
    line-height: 0.68rem;
    text-align: center;
    font-size: 0.3rem;
    color: #D0FFFD;
}

 .info-bottom{
    width: 100%;
    position: relative;
    margin-top: 0.1rem;
    height:3.5rem;
     .info-title{
        text-align: left;
    }
     .info-bottom-position{
        position: absolute;
        top: 0rem;
        left: 0.12rem;
        z-index: 100;
    }
}
//大于1024
 @media screen and (min-width: 1025px) {
    .info-bottom{
        width: 100%;
        position: relative;
        margin-top: 0.1rem;
        height:3.5rem;
        .info-title{
            text-align: left;
        }
        .info-bottom-position{
            position: absolute;
            top: 0rem;
            left: 0.12rem;
            z-index: 100;
        }
    }
    .scale-position1{
        position: absolute ;
        bottom:0%;
        left: 0;
    }
    .scale-position2{
        position: absolute ;
        bottom: 0%;
        left: 1.42rem;
    }
    .scale-position3{
        position: absolute ;
        bottom: 0%;
        left: 2.84rem;
    }
}
// 小于1024
@media screen and (max-width: 1024px) {
    .scale-position1{
        position: absolute ;
        bottom:-35%;
        left: 0;
    }
    .scale-position2{
        position: absolute ;
        bottom:-35%;
        left: 1.42rem;
    }
    .scale-position3{
        position: absolute ;
        bottom:-35%;
        left: 2.84rem;
    }
    .info-content{
        position: absolute;
        left: 0.12rem;
        top: 3.0rem;
        width: 100%;
    }
    .project-num{
        display:flex;
        .project-title{
            width: 3.5rem;
            line-height: 0.48rem;
            font-size: 0.08rem;
            color: #58D2FF;
        }
     .project-num-box{
        width: 2rem;
        display:flex;
         .num{
            width: 0.5rem;
            height: 0.48rem;
            background: url("../../../assets/screen/objectNum.png") no-repeat 0 0;
            background-size: 100% 100%;
            line-height:0.48rem;
            text-align: center;
            font-size: 0.3rem;
            color: #D0FFFD;
            margin-right: 0.08rem;
            }
        }
    }
    .arrears-amount{
     display:flex;
     .arrears-amount-title{
        width: 1.8rem;
        line-height: 0.48rem;
        font-size: 0.16rem;
        color: #58D2FF;
    }
     .arrears-amount-num{
        width: 2.12rem;
        height: 0.48rem;
        line-height: 0.48rem;
        background: url("../../../assets/screen/numberBg.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
}
 .people-box{
    display:flex;
     .txt1{
        width: 0.52rem;
        line-height: 0.48rem;
        font-size: 0.16rem;
    }
     .txt2{
        width: 0.7rem;
        line-height: 0.48rem;
        font-size: 0.16rem;
    }
     .txt3{
        width: 0.7rem;
        line-height: 0.48rem;
        font-size: 0.16rem;
    }
     .txt4{
        width: 0.7rem;
        line-height: 0.48rem;
        font-size: 0.16rem;
    }
     .num1{
        width: 0.43rem;
        height: 0.48rem;
        line-height: 0.48rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
     .num2{
        width: 0.43rem;
        height: 0.48rem;
        line-height: 0.48rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }
     .num3{
        width: 0.43rem;
        height: 0.48rem;
        line-height: 0.48rem;
        background: url("../../../assets/screen/sheji1.png") no-repeat 0 0;
        background-size: 100% 100%;
    }

}
    .info-bottom{
        width: 100%;
        position: relative;
        margin-top: 0.1rem;
        height:2.8rem;
        .info-title{
            text-align: left;
        }
        .info-bottom-position{
            position: absolute;
            top: 0rem;
            left: 0.12rem;
            z-index: 100;
        }
    }
}
 .scale-box{
    width: 100%;
    height: 3.5rem;
}
#chartBottom{
    z-index: 100;
}
.chart-top{
    height: 3.5rem;
    width: 100%;
}
.chart-bottom{
    height: 3.5rem;
    width: 100%;
}
 </style> 