<template>
  <div class="scale">
    <div :id="id" class='scale-flex scale-style' >
    </div>
    <div class="txt" style="width:33%; " :style="{color:'#58d2ff'}">
      {{name}}
      <div>
        <span class="scroe">{{scroe}}</span>
        <span class='danwei' :style="{color:color}">(个)</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"scaleEchart",
  props:{
    id: {
      type: String
    }, 
    color:{
      type:String
    },
    shadowColor:{
      type:String
    },
    bgColor:{
      type:String
    },
    name:{
      type:String
    },
    size:{

    }
  },
  data() {
    return{
      charts:"",
      scroePer:"",
      percentage:"",
      scroe:"",
      max:"",
    }
  },
  methods:{
    createEchart(max,scroe){
      let _this = this
      _this.max = max
      _this.scroe = scroe
      // var max = 100; //满刻度大小
      if(_this.scroe==0 && _this.max==0){
        _this.scroePer = 0
        _this.percentage = 0
      }else{
        _this.scroePer=_this.scroe/_this.max;
        _this.percentage = _this.scroe/(_this.max/100)
      }
      var data = _this.max * _this.scroePer;
      _this.charts = _this.$echarts.init(document.getElementById(this.id))
      _this.charts.setOption({
        title: {
          top: '75%',
          left: 'center',
          text: _this.percentage?_this.percentage + '%':0 + '%',
          textStyle: {
            color: _this.percentage==100?'#19223c':"#d0fffd",
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: _this.size
          }
        },
        series: [
          {
            type: 'liquidFill',
            itemStyle: {
              opacity:0.8,//波浪的透明度
              shadowBlur: 10,//波浪的阴影范围
              shadowColor:_this.shadowColor//阴影颜色
            },
            radius:'80%',
            shape:"circle",
            //水波
            color:[_this.color],
            data: [{
              value: _this.scroePer,
            }],
            // background: '#000',
            center: ['50%', '80%'],
            backgroundStyle: {
              color: _this.bgColor
            },
            label: {
              normal: {
                formatter: '',
                textStyle: {
                  fontSize: 12
                }
              }
            },
            outline: {
              itemStyle: {
                  borderColor: 'transparent',
                  borderWidth: 5
              },
              borderDistance: 0
            }
          },
          //外环线
          {
            "color": [_this.color, 'transparent'],
            "type": "pie",
            "center": ["50%", "80%"],
            "radius": ["80%", "82%"],
            "hoverAnimation": false,
            "data": [{
                "name": "",
                "value": data,
                "label": {
                  "show": false,
                  "position": "center",
                  "color": "#fff",
                  "fontSize": 38,
                  "fontWeight": "bold",
                  "formatter": function() {
                    return data
                    }
                  }
                },
                { //画剩余的刻度圆环
                "name": "",
                "value": _this.max - data,
                "label": {
                    show: false
                },
                labelLine: {
                    show: false
                }
              }
            ]
          }
        ]   
      })
    },
  },
  created(){},
  mounted(){
    this.createEchart()
  },
}
</script>
<style scoped lang="less">
  .scale{
      position: relative;
      .scale-flex{
      position: absolute;
      top: 0;
      left: 0;
    }
    .txt{
      position: absolute;
      top: 3.5rem;
      font-size: 0.16rem;
    }
  }
 .scroe{
    font-size: 0.29rem;
    color: #d0fffd;
  }
  .danwei{
    font-size: 0.14rem;
  }
  .scale-style{
    width: 33%;
    height: 3.5rem;
  }
 @media screen and (max-width: 1024px) {
    .scale{
      position: relative;
      .scale-flex{
      position: absolute;
      top: -0.09rem;
      left: 0;
    }
    .txt{
      position: absolute;
      top: 3.4rem;
      font-size: 0.16rem;
    }
  }
    .scroe{
      font-size: 0.22rem;
      color: #d0fffd;
    }
    .danwei{
      font-size: 0.12rem;
    }
    .scale-style{
      width: 33%;
      height: 3.5rem;
    }
 }
</style>
