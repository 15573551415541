<template>
  <div class="bg" :style="{backgroundImage:'url('+bg2+')'}">
    <div class="header">
      <div class="logo-box">
        <div :class="type == 1?'weather':''" v-if='!tablePage'>
          <div v-if="type == 1"   @click="toTabPage()" class="return">
            <img :src="goback" alt="">
          </div>
        </div>

        <div class="logo" @click="showModel()">
          <img v-if="companyLogo" :src="companyLogo" alt="">
         <div v-else :class="(tablePage || type == 0)?'logo-titles':'logo-title'">
          <span v-if="type==1">{{companyName}}</span>
          <span v-if="type==0">{{engineeringName}}</span>
        </div>
        </div>
        <div class="interval dingwei">
        </div>
      </div>
      <div class="title">
        <span v-if="tablePage">{{ mationInfo.companyScreenTitle }}</span>
        <span v-if="!tablePage">{{ mationInfo.projectScreenTitle }}</span>
        <!-- <img class="title-img" :src="projectTitle" alt=""> -->
      </div>
      <div class="date-box" >
        <div class="left">
          <p class="time">{{moment(dataTime).format('HH:mm:ss')}}</p>
          <p class="date">{{moment(dataTime).format('YYYY年MM月DD日')}}</p>
        </div>

        <div class="tab-project">
          <div @click="showOpenListFn()" class="more cursor-pointer">
            <img  src="@/assets/screen/more.png" alt="">
          </div>
          <!-- <div class="tab-list" v-if="showOpenList">

            <div class="select-tab cursor-pointer" v-if="SwitchInfo.projectScreenPermissionStatus == 1"  @click="open(2)">
              {{SwitchInfo.projectScreenTitle}}
            </div>
            <div class="select-tab cursor-pointer" v-if="SwitchInfo.salaryScreenPermissionStatus == 1"  @click="open(3)">
              {{SwitchInfo.salaryScreenTitle}}
            </div>
          </div> -->
        </div>
        <!-- <div class="logout" @click="logout">
          <img src="@/assets/screen/logout.png" alt="">
        </div> -->
        <!-- 全屏 -->
        <div class="full" @click="fullScreenFn">
          <img v-if="!fullscreen" src="@/assets/screen/develop.png" alt="">
          <img v-if="fullscreen" src="@/assets/screen/packup.png" alt="">
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-left" >
        <!-- <information-statistics ref="information" v-if="tablePage" :companyInfo="companyInfo" :abnormalProjectInfo="abnormalProjectInfo"> -->
        <information-statistics ref="information" v-if="tablePage" >
        </information-statistics>
        <!-- <projectInfo ref="projectinfo" v-if="!tablePage" :selectProjectData="selectProjectData" :ProjectSettleTotalData="ProjectSettleTotalData"> -->
        <projectInfo ref="projectinfo" v-if="!tablePage">
        </projectInfo>
      </div>
      <div class="content-center">
        <centerData 
          @getId="getEngineeringId"
          :key="1"
          ref="centerData"
          :companyName="companyName"
          :companyLogo="companyLogo"
          :engineeringId="engineeringId"
          :isFirst="tablePage"
        ></centerData>
      </div>
      <div class="content-right">

        <rightData
          :key="2"
          ref="rightData"
          :companyName="companyName"
          :engineeringId="engineeringId"
        ></rightData>
      </div>
    </div>
    <!-- <div class='model-shadow' v-if='visible'>
      <div class="model">
        <div class="title">请选择企业及角色</div>
        <div class="content">
          <div class="select-box mb" style="font-size:.18rem">
            <span class="txt-style">请选择企业：</span>
            <select class='select' v-model="companyValue" @change="companyChange">
              <option :value="item.comSubId" v-for="(item,index) in companyList" :key="index">{{item.companyName}}</option>
            </select>
            <div v-show="showCompanyCheck" class="check_company">企业不能为空</div>
          </div>
          <div class="select-box mt" style="font-size:.18rem">
            <span class="txt-style">请选择角色：</span>
            <select class='select' v-model="corpUserValue"  @change="corpUserChange">
              <option :value="item.corpUserId" v-for="(item,index) in roleList" :key="index" v-show="item.name">{{item.name}}</option>
            </select>
            <div v-show="showCorpUseryCheck" class="check_company">角色不能为空</div>
          </div>
        </div>
        <div class='footer'>
          <div v-if='this.companyName' class="close btn" @click="close">取消</div>
          <div class="submit btn" @click="submit">确认</div>
        </div>
      </div>
    </div> -->


    <div class='model-shadow' v-if='showOpenList'>
      <div class="models">
        <div class="models-box" v-if="!showNull">
          <div class="modelcontent cursor-pointer" @click="open(2)" v-if="SwitchInfo.projectScreenPermissionStatus == 1">
            <div class="modeltext"   >
              <!-- {{SwitchInfo.projectScreenTitle}} -->
              数字化劳动成果大屏
            </div>
          </div>
          <div class="modelcontent cursor-pointer" @click="open(3)" v-if="SwitchInfo.salaryScreenPermissionStatus == 1">
            <div class="modeltext"   >
              {{SwitchInfo.salaryScreenTitle}}
            </div>
          </div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
        <div class="models-box" v-if="showNull">
          <div class="zwqx">暂无权限</div>
          <div class="close cursor-pointer" @click="closeTab">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from "moment"
import InformationStatistics from "./components/InformationStatistics.vue"
import projectInfo from "./components/projectInfo.vue"
import rightData from '../../components/rightData/index'
import centerData from '@/components/centerData/index'
import { 
  companyRoleList,
  // roleList, 
  // cutCompanyRole,
  selectCompanyInfo,
  abnormalProjectTotal,
  selectProjectInfo,
  selectProjectSettleTotal,
  screenSwitchInfo,

 } from "../../services";
 import { 
  currCompanyShowDetailInfo
 } from "../../services/salary.js";
export default {
  name:"home",
  components:{
    InformationStatistics,
    projectInfo,
    rightData,
    centerData,
  },
  data(){
    return{
      Vue,
      moment,
      engineeringName:"",
      // cloneDeep,
      title:require("@/assets/screen/title.png"),
      bg1:require("@/assets/screen/bg1.png"),
      bg2:require("@/assets/screen/bg2.png"),
      goback:require('@/assets/screen/goBack1.png'),
      projectTitle:require('@/assets/screen/projectTitle.png'),
      dataTime:"2021-05-01 00:00:00",
      tablePage:true,
      // 用户信息
      companyList:[],  // 企业列表
      companyValue:"",
      corpUserValue:"",
      roleList:[], // 角色列表
      showCompanyCheck:false,
      showCorpUseryCheck:false,
      // visible:false,
      companyName:"", //企业名称
      companyLogo:"",//企业logo
      engineeringId: null, // 工程id
      companyInfo:{
        abnormalProject: 0,
        arrearsMoney: 0,
        groupNum: 0,
        normalProject: 0,
        progressProject: 0,
        teamNum: 0,
        workerNum: 0,
      },
      // 2-项目经理
      // 11-企业主体
      // 12-财务总监
      // 13-工程部负责人
      // 14-工程管理员
      // 15-生产经理
      // 16-主管领导
      userList:[
        {
          code:2,
          name:"项目经理"
        },
        {
          code:11,
          name:"企业主体"
        },
        {
          code:12,
          name:"财务总监"
        },
        {
          code:13,
          name:"工程部负责人"
        },
        {
          code:14,
          name:"工程管理员"
        },
        {
          code:15,
          name:"生产经理"
        },
        {
          code:16,
          name:"主管领导"
        },
      ],
      abnormalProjectInfo:{
        "arrearsNum": 0,
        "redNum": 0,
        "yellowNum": 0
      },
      companyInfoShow:false,
      abnormalProjectInfoShow:false,
      //页面2的状态和数据
      selectProjectData:{
        "arrearsMoney": 0,
        "ename": "",
        "labourNum": 0,
        "subReqStatus": "1",
        "groupNum": 0,
        "workerNum": 0,
        "teamNum": 0
      },
      selectProjectDataShow:false,
      ProjectSettleTotalDataShow:false,
      ProjectSettleTotalData:{
        "workerNoApproval": 0,
        "groupApprovaled": 0,
        "groupNoApproval": 0,
        "approvaled": 0,
        "teamApprovaled": 0,
        "teamNoApproval": 0,
        "workerApprovaled": 0,
        "noApproval": 0
      },
      fullscreen: false,
      mationInfo:"",
      parames1:"",
      parames2:"",
      showOpenList:false,
      SwitchInfo:[],
      showNull:false,
    }
  },
  created(){
    this.projectId = sessionStorage.getItem('projectId')
    this.type = sessionStorage.getItem('type')
    //如说是项目人员 进入 只能看项目
    if(this.type == 0){
      Vue.ls.set("page",2)
      setTimeout(()=>{
      this.companyName = sessionStorage.getItem('companyName')
    },1000)
    }else if(this.type == 1){
      Vue.ls.set("page",1)
      this.companyName = sessionStorage.getItem('companyName')
    }
    
    this.getDate()
    this.InformationFn()
   },
  mounted(){
    if(sessionStorage.getItem('companyName')){
      console.log(Vue.ls.get("page"),"page")
      if(Vue.ls.get("page")==2){
        this.tablePage=false
        this.engineeringId = Vue.ls.get("engineeringId")
        this.initDataTow()
        setInterval(()=>{
          this.initDataTow()
        },1800000)
      }else{
        this.tablePage=true
        this.initData()
      }
    }
  },
  methods:{
    InformationFn(){
      currCompanyShowDetailInfo().then(res=>{
        this.mationInfo = res.data
      })
    },
    jumpTo(){
      this.$router.push({
        path:"/salary"
      })
    },
    // 获取第一个页面的数据
    initData(){
      Vue.ls.set("page",1)
      // 重新获取数据
      this.getselectCompanyInfo(),
      this.getabnormalProjectTotal()
      this.$refs.centerData.getProject()
      this.$refs.centerData.getWorkData()
      this.$refs.rightData.getTodayData()
      this.$refs.rightData.getAttence()
    },
    // 获取第二个页面的数据
    initDataTow(){
      Vue.ls.set("page",2)
      this.getselectProjectInfo();
      this.getselectProjectSettleTotal()
      // this.$refs.projectinfo.initData()
      this.$refs.centerData.getProject1(this.engineeringId)
      this.$refs.centerData.getWorkData1(this.engineeringId)
      this.$refs.rightData.getTodayData1(this.engineeringId)
      this.$refs.rightData.getAttence1(this.engineeringId)
    },
    //页面2换回页面1
    toTabPage(){
      this.tablePage = true
      this.initData()
    },
    // // 获取企业信息 左侧
    getselectCompanyInfo(){
      selectCompanyInfo().then(res=>{
        if(res.code === 200){
          if(res.data){
            this.companyInfoShow = true
            this.companyInfo = res.data
            this.$refs.information.initData(this.companyInfo)
          }else{
            this.companyInfoShow = true
            this.companyInfo = {
              abnormalProject: 0,
              arrearsMoney: 0,
              groupNum: 0,
              normalProject: 0,
              progressProject: 0,
              teamNum: 0,
              workerNum: 0,
            }
            this.$refs.information.initData(this.companyInfo)
          }
        } 
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 异常项目统计
    getabnormalProjectTotal(){
      abnormalProjectTotal().then(res=>{
        if(res.code === 200){
          if(res.data){
            this.abnormalProjectInfoShow = true
            this.abnormalProjectInfo = res.data
            this.$refs.information.initData2(this.abnormalProjectInfo)
          }else{
            this.abnormalProjectInfoShow = true
            this.abnormalProjectInfo = {
              "arrearsNum": 0,
              "redNum": 0,
              "yellowNum": 0
            }
            this.$refs.information.initData2(this.abnormalProjectInfo)
          }
        }
      })
    },
    // 页面2左侧  ovee
    getselectProjectInfo(){
      if(this.type == 0){
        this.parames2 = {
          projectId: this.projectId
        }
      }else {
        this.parames2 = {
          engineeringId: this.engineeringId 
        }
      }
      selectProjectInfo({
        ...this.parames2
      }).then(res=>{
        if(res.code == 200){
          if(res.data){
            this.selectProjectDataShow = true;
            this.selectProjectData = res.data;
            if(this.type == 0){
              this.engineeringName = res.data.engineeringName
            }
            this.$refs.projectinfo.initData2(this.selectProjectData)
          }else{
            this.selectProjectDataShow = true;
            this.selectProjectData = {
              "arrearsMoney": 0,
              "ename": "",
              "labourNum": 0,
              "subReqStatus": "1",
              "groupNum": 0,
              "workerNum": 0,
              "teamNum": 0
            };
            this.$refs.projectinfo.initData2(this.selectProjectData)
          }
        }
      })
    },
    //页面2  异常项目  ovee
    getselectProjectSettleTotal(){
      if(this.type == 0){
        this.parames1 = {
          projectId: this.projectId
        }
      }else {
        this.parames1 = {
          engineeringId:this.engineeringId
        }
      }
      selectProjectSettleTotal({
        ...this.parames1
      }).then(res=>{
        if(res.code === 200){
          if(res.data){
            this.ProjectSettleTotalDataShow = true
            this.ProjectSettleTotalData = res.data
            this.$refs.projectinfo.initData(this.ProjectSettleTotalData)
          }else{
            this.ProjectSettleTotalDataShow = true
            this.ProjectSettleTotalData = {
              "workerNoApproval": 0,
              "groupApprovaled": 0,
              "groupNoApproval": 0,
              "approvaled": 0,
              "teamApprovaled": 0,
              "teamNoApproval": 0,
              "workerApprovaled": 0,
              "noApproval": 0
            }
            this.$refs.projectinfo.initData(this.ProjectSettleTotalData)
          }
        }
      })
    },
    getDate(){
      const _this = this
       _this.dataTime = new Date()
      setInterval(()=>{
        _this.dataTime = new Date()
      },1000)
    },
    //获取 用户所在企业列表
    getCompanyList(){
      companyRoleList().then(res=>{
        if(res.code === 200 && res.data){
          this.companyList = res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //获取角色列表
    // getRoleList(comSubId){
    //   roleList({
    //     comSubId
    //   }).then((res)=>{
    //     let roleList = res.data
    //     roleList.forEach((item)=>{
    //       this.userList.forEach(im=>{
    //         if(item.roleId == im.code){
    //           item.name = im.name
    //         }
    //       })
    //     })
    //     console.log(roleList)
    //     this.roleList = roleList
    //   })
    // },
    // 切换角色
    // getCutCompanyRole(corpUserId){
    //   cutCompanyRole({
    //     corpUserId
    //   }).then(res=>{
    //     if(res.code=== 200  && res.data){
    //       this.companyName = res.data.companyName
    //       this.companyLogo = res.data.companyLogo
    //       Vue.ls.set('companyName',this.companyName)
    //       this.visible = false
    //       this.initData()
    //     }
    //   })
    // },
    // 选择企业
    // companyChange(e){
    //   let companyValue = e.target.value
    //   if(companyValue){
    //     this.showCompanyCheck = false
    //     this.corpUserValue = ""
    //   }
    //   this.getRoleList(parseInt(companyValue))
    // },
    // 选择角色
    // corpUserChange(e){
    //   let corpUserValue = e.target.value
    //   if(corpUserValue){
    //     this.showCorpUseryCheck = false
    //   }
    //   console.log(corpUserValue)
    //   // this.getCutCompanyRole(corpUserValue)
    // },
    submit(){
      this.submitLoading = true
      if(this.companyValue){
        if(this.corpUserValue){
          this.showCompanyCheck = false
          this.showCorpUseryCheck = false
          this.getCutCompanyRole(this.corpUserValue)
          
        }else{
          this.showCorpUseryCheck = true
        }
      }else{
        this.showCompanyCheck = true
      }
    },
    // close(){
    //   if(this.companyName){
    //     this.visible = false
    //   }
    // },
    // showModel(){
    //   if(this.tablePage){
    //    this.visible = true
    //   }
    // },
    // 子组件向父组件传递工程id参数
    getEngineeringId(id) {
      this.tablePage = false
      this.engineeringId = id
      Vue.ls.set("engineeringId",this.engineeringId)
      //请求页面2 的数据
      this.initDataTow()
    },
    // 退出登录
    // logout() {
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('companyName')
    //   localStorage.removeItem('page')
    //   localStorage.removeItem('engineeringId')
    //   this.$router.push({ path: "/" })
    // }

    fullScreenFn() {
        let element = document.documentElement;
        if (this.fullscreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            // IE11
            element.msRequestFullscreen();
          }
        }
        this.fullscreen = !this.fullscreen;
      },
      showOpenListFn(){
        console.log("123")
        screenSwitchInfo().then(res=>{
          if(res.code === 200 && res.data){
              this.SwitchInfo = res.data
              if(this.SwitchInfo.projectScreenPermissionStatus == 0 && this.SwitchInfo.salaryScreenPermissionStatus == 0){
                this.showOpenList = !this.showOpenList
                this.showNull = true
                console.log(1)
              }else{
                this.showOpenList = !this.showOpenLis
                this.showNull = false
              }
     
            // this.SwitchInfo.projectScreenPermissionStatus = 1
            // this.SwitchInfo.salaryScreenPermissionStatus = 1
          }
        }).catch(err=>{
          console.log(err)
        })
       
      },
      open(type){
        console.log(type)
        // if(type==1){
        //   console.log(1)
        //   this.showOpenList = !this.showOpenList
        //   this.$router.push({
        //     path:"/"
        //   })

        // }

        if(type==2){
          console.log(1)
          this.showOpenList = !this.showOpenList
          this.$router.push({
            path:"/managerScreen/groupManager"
          })
        }
        // if(type==2){
        //   this.showOpenList = !this.showOpenList
        //   var token = sessionStorage.getItem('token')
        //   var projectId = sessionStorage.getItem('projectId')
        //   var companyName = sessionStorage.getItem('companyName')
        //   console.log("环境: ", )
        //   if(process.env.VUE_APP_SERVER == "https://nllcbdev.lanlingcb.com/llcb"){
        //     window.open("https://nllcbdev.lanlingcb.com/managerScreen", "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://nllcbtest.lanlingcb.com/llcb/"){
        //     console.log("https://nllcbtest.lanlingcb.com/managerScreen?token="+token+"&projectId="+projectId+"&companyName="+companyName)
        //     window.open("https://nllcbtest.lanlingcb.com/managerScreen?token="+token+"&projectId="+projectId+"&companyName="+companyName, "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://prenllcb.kaishuihu.com/llcb"){
        //     window.open("https://prenllcb.kaishuihu.com/managerScreen", "_blank");
        //   }
        //   if(process.env.VUE_APP_SERVER == "https://nllcb.kaishuihu.com/llcb"){
        //       window.open("https://nllcb.kaishuihu.com/managerScreen", "_blank");
        //   }
        // }

        if(type==3){
          this.$router.push({
            path:"/salary"
          })
          this.showOpenList = !this.showOpenList
        }
        

      },
      closeTab(){
        this.showOpenList = false
      },

  },

    
}
</script>
<style scoped lang="less">
  .cursor-pointer{
    cursor:pointer;
  }
  .mt{
    margin-top: .2rem;
  }
  .mb{
    margin-bottom: .2rem;
  }
  .bg{
      position: relative;
      max-width: 19.2rem;
      min-height: 10.8rem;
      // background: url("../../assets/screen/bg2.png") no-repeat 0 0 ;
      background-repeat:no-repeat;
      background-size:100% 100%;
      .header{
        height: 1rem;
        width: 100%;
        position: relative;

        .logo-box{
          width: 3.3rem;
          height: .50rem;
          position: absolute;
          top: .45rem;
          left: .34rem;
          display: flex;
          flex-wrap: wrap;
          .logo{
            width: 2.0rem;
            height: .5rem;
            font-size: .2rem;
            line-height: .38rem;
            color: #d0fffd;
          }

           .logo-title{
            width: 2.0rem;
            font-size: 0.16rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
          }
          .logo-titles{
            width: 3rem;
            font-size: 0.16rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
          }
          .interval{
            // border-right: .03rem solid #236495;
            height: .19rem;
            margin-top: .08rem;
            margin-left: .3rem;
          }
          .dingwei{
            position: absolute;
            top: 0;
            left: 1.3rem;
          }
          .weather{
            margin-right: .20rem;
            width: 1.0rem;
            height: .50rem;
            text-align: right;
            font-size: .20rem;
            color: #d0fffd;

            .return{
              position: absolute;
              top: -0.2rem;
              width: 1.2rem;
              height: 0.57rem;
              cursor: pointer;
              img{
                width: 100%;
              }
            }
          }
        }
        .style-logo-box{

        }

        .title{
          position: absolute;
          width: 3.70rem;
          height: .50rem;
          left: 50%;
          margin-left:-1.85rem;
          margin-top: .32rem;
          font-size: 0.25rem;
          line-height: 0.9rem;
          color: #d0fffd;
          font-weight: bold;
          .title-img{
            width:100%
          }
        }
        .date-box{
          width: 3.6rem;
          position: absolute;
          top: 0.35rem;
          right: 0.25rem;
          display: flex;
          flex-wrap: wrap;
          font-size: .20rem;
          color: #d0fffd;
          justify-content: space-between;
          .left{
            position: relative;
            text-align:left;
          }
          .time{
            font-size: .26rem;
          }
          .date{
            font-size: .18rem;
          }
          .interval{
            // border-right: .03rem solid #236495;
            height: .19rem;
            margin: 0 .1rem;
            margin-top: .2rem;
          }
          .logout{
            position: relative;
            top: .1rem;
            right: .1rem;
            width: 0.88rem;
            height:.37rem;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .full{
            position: relative;
            top: -0.1rem;
            right: .1rem;
            width: 0.55rem;
            height:.37rem;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
        }
      }
      .content{
        position: relative;
        .content-left{
          position: absolute;
          // background: #fff;
          // opacity: .3;
          width: 4.30rem;
          height: 9.30rem;
          top: .10rem;
          left: .40rem;
        }
        .content-center{
          position: absolute;
          width: 8.50rem;
          height: 9.30rem;
          // background: #fff;
          // opacity: .3;
          top: .10rem;
          left: 50%;
          margin-left: -4.25rem;
        }
        .content-right{
          position: absolute;
          // background: #fff;
          // opacity: .3;
          width: 4.30rem;
          height: 9.30rem;
          top: .10rem;
          right: .40rem;
        }
      }
    .model-shadow{
      position: absolute;
      top: 0;
      left: 0;
      width: 19.20rem;
      min-height: 10.80rem;
      background:rgba(1,14,34,.7);
      z-index: 1000;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .models{
        position:absolute;
        top: 27%;
        left: 50%;
        margin-left: -4.00rem;
        margin-top: -2.00rem;
        width: 8.00rem;
        height: 7.00rem;
        background: url("../../assets/screen/modelBox.png") no-repeat center center;
        background-size: 100%;
 
        .models-box{
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 1.4rem;
          box-sizing: border-box;
          .modelcontent{
            width: 2.4rem;
            height: 4rem;
            background: url("../../assets/screen/modelPro.png") no-repeat center center;
            background-size: 100%;
            padding-top: 1.8rem;
            box-sizing: border-box;
            .modeltext{
              font-size: 0.2rem;
              padding: 0.1rem;
              box-sizing: border-box;
              color: #D0FFFD;
            }
          }
          .close{
              position: absolute;
              width: 0.5rem;
              height: 0.5rem;
              bottom: 0;
              left: 50%;
              margin-left: -0.25rem;
              z-index: 1000;
              background: url("../../assets/screen/close.png") no-repeat center center;
              background-size: 100%;
            }
        }
        .zwqx{
          font-size: 0.22rem;
          margin-top: 2rem;
          color:#00adff;
        }
      }
  
      .model{
        position:absolute;
        top: 25%;
        left: 50%;
        margin-left: -2.00rem;
        width: 4.00rem;
        height: 3.00rem;
        background:rgba(1,18,39,.8);
        border: .02rem solid #00adff;
        border-radius: .05rem;
        box-shadow: 0 0 .12rem rgb(0 173 255 / 75%);
        .title{
          color: #58d2ff;
          font-size: .16rem;
          margin-top: .20rem;
        }
        .content{
          margin-top: .30rem;
          .select-box{
            margin-top: .20rem;
            height:.48rem;
            .txt-style{
              color: #58d2ff;
              font-size: .14rem;
            }
            .select{
              width: 1.40rem;
              height: .30rem;
              background:rgba(1,18,39,.8);
              color:#00adff;
              border: .01rem solid #00adff;
              border-radius: .05rem;
              box-shadow: 0 0 .06rem rgb(0 173 255 / 75%);
              outline: none
            }
            .check_company{
              margin-left:.18rem;
              margin-top: .10rem;
              color:#58d2ff;
              font-size: .12rem;
            }
          }
        }
        .footer{
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: .50rem;
          display: flex;
          justify-content: flex-end;
          .submit{
            margin-right: .30rem;
            font-size: .14rem;
          }
          .close{
            margin-right: .10rem;
            font-size: .14rem;
          }
          .btn{
            width: .50rem;
            height: .30rem;
            line-height: .30rem;
            color: #58d2ff;
            border: .01rem solid #00adff;
            border-radius: .05rem;
            cursor:pointer;
          }
        }
      }
    } 
  
  }
  .tab-project{
    position: relative;
  }
  .tab-list{
    position:absolute;
    top: 0.5rem;
    left: -1rem;
    width: 2.4rem;
    z-index: 888;
    min-height: 0.8rem;
    background: #08436d;
    border: 1px solid #00adff;
    padding: 0.08rem;
    box-sizing: border-box;
  }
  .select-tab{
    font-size: 0.12rem;
    border-bottom: 1px solid #00adff;
    height: 0.25rem;
    margin-top: 0.12rem;
  }
  .more{
    width: 0.55rem;
    height:.35rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
