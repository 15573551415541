import { request,METHOD } from '@/utils/request.js'
const apiList = {
  selectNowTotal: '/business/subReqWages/selectNowTotal', // 首页今日数据
  selectExamimeTotal: '/business/subReqExamime/selectExamimeTotal', // 首页今日数据
  selectNowProjectTotal :'/business/subReqWages/selectNowProjectTotal', // 内页今日数据
  selectEngineeringExamimeTotal :'/business/subReqExamime/selectEngineeringExamimeTotal', // 内页今日数据
  selectProjectData :'/business/subReqWages/selectProjectData', // 项目统计数据
  selectProjectDemandTotal: '/business/subReqPerson/selectProjectDemandTotal', // 内页项目需求统计
}
/**
 * 应用模块列表
 * @param [string] status 搜索-状态
 */
export function selectNowTotal(parameter) {
  return request({
    url: apiList.selectNowTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function selectExamimeTotal(parameter) {
  return request({
    url: apiList.selectExamimeTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function selectNowProjectTotal(parameter) {
  return request({
    url: apiList.selectNowProjectTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function selectEngineeringExamimeTotal(parameter) {
  return request({
    url: apiList.selectEngineeringExamimeTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function selectProjectData(parameter) {
  return request({
    url: apiList.selectProjectData,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}

export function selectProjectDemandTotal(parameter) {
  return request({
    url: apiList.selectProjectDemandTotal,
    method: METHOD.POST,
    data: parameter,
    isformData:true
  })
}