<template>
  <div class="info">
    <div class="infoTop">
      <div class="info-title info-top-position">
          项目基本信息统计
      </div>
      <div class="info-content">
        <div class="project-title-box">
          <div class="project-log">工程</div>
          <div class="project-title">{{selectProjectData.engineeringName}}</div>
        </div>
        <div class="project-state">
          <div class='state-pic-box'>
            <img v-if='selectProjectData.subReqStatus == 0' :src="lamp.red0" alt="">
            <img v-if='selectProjectData.subReqStatus == 1' :src="lamp.cyan1" alt="">
            <img v-if='selectProjectData.subReqStatus == 2' :src="lamp.yellow2" alt="">
            <img v-if='selectProjectData.subReqStatus == 3' :src="lamp.green3" alt="">
          </div>
          <div class='state-txt'>
            <!-- subReqStatus 拖欠状态 0 红灯 1半拖欠 2黄灯 3 正常 -->
            <div class="lamp-status" style="color:#ff0000;text-shadow: 5px 5px 10px #FF0000;" v-if='selectProjectData.subReqStatus == 0'>红灯</div>
            <div class="lamp-status" style="color:#ffe900 ;text-shadow: 5px 5px 10px #ffe900;" v-if='selectProjectData.subReqStatus == 1'>半拖欠</div>
            <div class="lamp-status" style="color:#ff7f00 ;text-shadow: 5px 5px 10px #ff7f00;" v-if='selectProjectData.subReqStatus == 2'>黄灯</div>
            <div class="lamp-status" style="color:#7aff00 ;text-shadow: 5px 5px 10px #7aff00;" v-if='selectProjectData.subReqStatus == 3'>正常</div>
          </div>
        </div>
        <div class="statistical-info">
          <div class="project-num mb">
            <div class="project-title">
                共计劳务公司（家）
            </div>
            <div class="project-num-box">
              <div class="num"  v-for="(item,index) in getlabourNum" :key="index">
                  {{item}}
              </div>
            </div>
          </div>
          <div class="default-quota-box">
            <div class="default-quota-txt">
              拖欠工资总额（元）
            </div>
            <div class="default-quota-num">
             {{selectProjectData.arrearsMoney}}
            </div>
          </div>
          <div class="involve-num-box">
            <div class="involve-leader-txt">
              涉及领队人数
            </div>
            <div class="involve-leader-num">
              {{selectProjectData.teamNum}}
            </div>
            <div class="involve-group-txt">
              涉及班组数量
            </div>
            <div class="involve-group-num">
              {{selectProjectData.groupNum}}
            </div>
          </div>
          <div class="involve-all-box">
            <div class="involve-all-txt">
              涉及总人数
            </div>
            <div class="involve-all-num">
              {{selectProjectData.workerNum}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-bottom">
     <div class="info-title info-bottom-position">
        项目结算信息统计
      </div>
      <div id="chartTop" class="chart-top" > 
      </div>
      <div id="chartColumn" class="chart-column" style="">
      </div>
    </div>
  </div>
</template>
<script>
import  'echarts-liquidfill'
export default {
  name:"info",
  components:{
  },
  props:{
    // selectProjectData:{
    //   type: Object
    // },
    // ProjectSettleTotalData:{
    //   type: Object
    // },
  },
  data(){
    return{
      selectProjectData:{
        "arrearsMoney": 0,
        "ename": "",
        "labourNum": 0,
        "subReqStatus": "1",
        "groupNum": 0,
        "workerNum": 0,
        "teamNum": 0
      },
      ProjectSettleTotalData:{
        "workerNoApproval": 0,
        "groupApprovaled": 0,
        "groupNoApproval": 0,
        "approvaled": 0,
        "teamApprovaled": 0,
        "teamNoApproval": 0,
        "workerApprovaled": 0,
        "noApproval": 0
      },
      pieData: [
          {value: 0, name: '已审批', },
          {value: 0, name: '待审批'},
      ],
      projectNum:[  // 正在进行中的项目（个）
          0,0,0,1
      ],
      arrearsNum:"100000.00", // 拖欠总金额（元）
      defaultUnit:{
        leader:"100",
        group:"100",
        people:"100",
      },
      lamp:{
        yellow2:require("@/assets/screen/icon1.png"),
        green3:require("@/assets/screen/icon2.png"),
        cyan1:require("@/assets/screen/icon3.png"),
        red0:require("@/assets/screen/icon4.png"),
      },
      charts:"",
      chartColumn:"",
      noApprova:[],//未审批
      approvaled:[],//待审批
      getlabourNum:[0],
      page2:0.2,
        s:{
          chartTop:{
              radius:['40%', '25%'],
              name:10, 
              percent:10, 
              value:10, 
              g:10, 
              center:["48%","47%"]
          },
          chartBottom:{
            fontSize:10,
            lineHeight: 145,
            top:"45%",
            height:70,
            legend_fontSize:12,
            barWidth:5,   //左侧二级页面结算审批单柱状图粗细
            series_fontSize:10,
            legend:"90%", //左侧二级页面结算审批单柱状图待审批已审批偏移量
            barGap:1,  //  结算单审批y轴 结算单 柱状图间距
          },
          scale:{
              size:12
          }
      },
        l:{
            chartTop:{
               radius:['45%', '30%'],
               name:16,   //  已审批 待审批
               percent:20, 
               value:18, 
               g:18, 
               center:["50%","55%"]
            },
            chartBottom:{
                fontSize:12,
                lineHeight: 240,
                top:"37%",
                height:140,
                legend_fontSize:16,
                barWidth:20, //左侧二级页面结算审批单柱状图粗细
                series_fontSize:14,
                legend:"85%",  //左侧二级页面结算审批单柱状图待审批已审批偏移量
                barGap:0.3,  //  结算单审批y轴 结算单 柱状图间距
            },
            scale:{
                size:18
            }
        },
        //适配苹果电脑
        apple:{
            chartTop:{
               radius:['40%', '25%'],
               name:16, 
               percent:16, 
               value:16, 
               g:16, 
               center:["50%","50%"]
            },
            chartBottom:{
                fontSize:12,
                lineHeight: 180,
                top:"38%",
                height:100,
                legend_fontSize:16,
                barWidth:10, //左侧二级页面结算审批单柱状图粗细
                series_fontSize:14,
                legend:"83%",  //左侧二级页面结算审批单柱状图待审批已审批偏移量
                barGap:0.3,  //  结算单审批y轴 结算单 柱状图间距
            },
            scale:{
                size:18
            }
        },
        xl:{
            chartTop:{
               radius:['75%', '50%'],
               name:16, 
               percent:24, 
               value:24, 
               g:16, 
               center:["50%","43%"]
            },
            chartBottom:{
                fontSize:12,
                lineHeight: 240,
                top:"37%",
                height:140,
                legend_fontSize:16,
                barWidth:20, //左侧二级页面结算审批单柱状图粗细
                series_fontSize:14,
                legend:"80%", //左侧二级页面结算审批单柱状图待审批已审批偏移量
                barGap:0.3,  //  结算单审批y轴 结算单 柱状图间距
            },
            scale:{
                size:18
            }
        },
        size:{
        }
    }
  },
  created(){
    this.getWidth()
  },
  mounted(){
    this.$nextTick(function() {
        this.drawPieTop()
        this.creatgeChartColumn()
      }) // 延迟调用
    // console.log(this.companyInfo,'companyInfo')
    //初始化格式
    // this.initData()
  },
  destroyed(){},
  methods:{
    getWidth(){
        this.windowX = document.documentElement.clientWidth
        console.log(this.windowX,'----')
        if(this.windowX<=1024){
            this.size = this.s
        }else if(this.windowX == 1440){
          console.log(1400,"苹果");
            this.size = this.apple
            return
        } else if(this.windowX > 1024 && this.windowX <= 1920){
          console.log('区间')
            this.size = this.l
        }else if(this.windowX >1920){
            this.size = this.xl
        }
    },
    initData(data){
      console.log(data,'ProjectSettleTotalData')
      this.noApprova = []
      this.approvaled = []
      this.ProjectSettleTotalData = data
      this.noApprova.push(this.ProjectSettleTotalData.workerNoApproval)  // 工人已审批
      this.noApprova.push(this.ProjectSettleTotalData.groupNoApproval) // 组长未审批数量
      this.noApprova.push(this.ProjectSettleTotalData.teamNoApproval)  // 领队未审批数量

      this.approvaled.push(this.ProjectSettleTotalData.workerApprovaled) 	//工人已审批数量
      this.approvaled.push(this.ProjectSettleTotalData.groupApprovaled)  //组长已审批数量
      this.approvaled.push(this.ProjectSettleTotalData.teamApprovaled)  // 领队已审批数量

        //饼图
      this.pieData[0].value = this.ProjectSettleTotalData.approvaled
      this.pieData[1].value = this.ProjectSettleTotalData.noApproval
      // this.pieData[0].value = 50
      // this.pieData[1].value = 50
      this.page2 = (this.pieData[0].value + this.pieData[1].value) /50
      this.drawPieTop()
      this.creatgeChartColumn()   
    },
    initData2(data){
      this.selectProjectData = data
      if(this.selectProjectData.labourNum){
        this.getlabourNum = (this.selectProjectData.labourNum+"").split("")
      }else{
        this.getlabourNum = [0]
      }

    },
    drawPieTop() {
        let _this = this
          var data = [];
          let typeArr = []
          typeArr.push( _this.pieData[0].value)
          typeArr.push( _this.pieData[1].value)
          var color = [ '#43ccfd','#fcff00']
          for (var i = 0; i < _this.pieData.length; i++) {
            console.log(typeArr.indexOf(0)==-1)
            if(typeArr.indexOf(0)==-1){
                data.push({
                    value: _this.pieData[i].value,
                    name: _this.pieData[i].name,
                    itemStyle: {
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 10,
                        },
                        normal: {
                            borderWidth: 0,
                            shadowBlur: 15,
                            borderColor: color[i],
                            shadowColor: color[i]
                        }
                    }
                },
                {
                value: this.page2,
                name: '',
                itemStyle: {
                  normal: {
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    },
                    color: 'rgba(0, 0, 0, 0)',
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0
                  }
                }
              }
            );
          }else{
            data.push({
              value: _this.pieData[i].value,
              name: _this.pieData[i].name,
                itemStyle: {
                  itemStyle: {
                      borderRadius: 10,
                      borderColor: '#fff',
                      borderWidth: 10,
                  },
                  normal: {
                      borderWidth: 0,
                      shadowBlur: 15,
                      borderColor: color[i],
                      shadowColor: color[i]
                  }
                }
              },
            );
          }
        }
        this.charts = this.$echarts.init(document.getElementById("chartTop"))
        this.charts.setOption({
          // 用例
            // backgroundColor: '#0A2E5D',
            color: color,
            title: {
                text: '',
                top: '48%',
                textAlign: "center",
                left: "49%",
            },
            graphic: {
              elements: [{
                type: "image",
                z: 3,
                style: {
                    // image: img,
                    width: 178,
                    height: 178
                },
                left: 'center',
                top: 'center',
                position: [100, 100]
              }]
            },
            tooltip: {
                show: false
            },
            toolbox: {
                show: false
            },
          series: [{
            name: '半径模式',
            type: 'pie',
            clockWise: false,
            hoverAnimation: false,
            radius: _this.size.chartTop.radius,
            center: _this.size.chartTop.center,
            roseType: 'radius',
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                label: {
                    formatter: function(params) {
                        var percent = 0;
                        var total = 0;
                        for (var i = 0; i < _this.pieData.length; i++) {
                            total += _this.pieData[i].value;
                        }
                        if(total == 0){
                          percent == 0
                        }else{
                          percent = ((params.value / total) * 100).toFixed(0);
                        }
                        if (params.name !== '') {
                            return  '{name|'+ params.name+'}'+ '\n{percent|' +percent+ '%}' + '\n' + '{value|'+ params.value + '}' + '{g|(单)}'
                        } else {
                            return '';
                        }
                    },
                    rich:{
                        name: {
                            color: '#58d2ff',
                            fontSize: _this.size.chartTop.name,
                            fontWeight: 400,
                            padding: [5, 0, 0, 5]
                        },
                        percent:{
                            color: '#d0fffd',
                            fontSize: _this.size.chartTop.percent,
                            fontWeight: 800,
                            padding: [5, 0, 0, 5] 
                        },
                        value:{
                            color: '#58d2ff',
                            fontSize: _this.size.chartTop.value,
                            fontWeight: 800,
                            padding: [5, 0, 0, 5] 
                        },
                        g:{
                            color: '#58d2ff',
                            fontSize:  _this.size.chartTop.g,
                            fontWeight: 400,
                            padding: [5, 0, 0, 5]  
                        }
            
                    },
                },
                labelLine: {
                  length: 8,
                  length2: 20,
                  show: true,
                  align: 'left'
                }
              }
            },
            data: data // 数据来源
            }]
        })
    },
    creatgeChartColumn(){
      let _this = this
      _this.chartColumn = _this.$echarts.init(document.getElementById("chartColumn"));
      _this.chartColumn.setOption({
        title:{
          text:"结算单审批情况",
          textStyle:{
            color:"#58d2ff",
            fontSize:10,
            lineHeight:  _this.size.chartBottom.lineHeight,
            align:"right"
          }
        },
        legend: { 
          data: ["待审批", "已审批"],
          x: 'center',
          y: _this.size.chartBottom.legend,
          textStyle: {
            color: "rgba(250,250,250,0.6)",
            fontSize: _this.size.chartBottom.fontSize,
          }
        },
        grid:{
          left:80,
          top:_this.size.chartBottom.top,
          height:_this.size.chartBottom.height,
        },
        barWidth: 13,
        xAxis: {
          type: 'value',
          minInterval:1,
          splitLine: {
            show:false,
            lineStyle: {
              color: "rgba(255,255,255,0.2)"
            }
          },
          axisTick: {
            show: false
          },
          axisLine: { //  改变x轴颜色
            show:true,
            lineStyle: {
              color: '#26D9FF'
            }
          },
          axisLabel: { //  改变x轴字体颜色和大小
            margin: 2,
            textStyle: {
              color: "#d0fffd",
              fontSize: 10,
              
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['工人结算单', '组长结算单', '领队结算单'],
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: { //  改变y轴颜色
            show:false,
            lineStyle: {
              color: '#fcff00'
            }
          },
          axisLabel: { //  改变y轴字体颜色和大小
            //formatter: '{value} m³ ', //  给y轴添加单位
            textStyle: {
              color: "#d0fffd",
              fontSize: 10
            }
          }
        },
        series: [{
          type: 'bar',
          name: "待审批",
          barWidth: _this.size.chartBottom.barWidth,
          barGap: _this.size.chartBottom.barGap,
          itemStyle: {
            normal: {
              label: {
                show: true, //开启显示
                position: 'right', //在上方显示
                textStyle: { //数值样式
                  color: "#d0fffd",
                  fontSize: _this.size.chartBottom.series_fontSize,
                  fontWeight: 400
                }
              },
                color:"#fcff00"
              }
            },
              data: _this.noApprova
            },
            {
              type: 'bar',
              name: "已审批",
              barWidth: _this.size.chartBottom.barWidth,
              barGap: _this.size.chartBottom.barGap,
              position:[10,10],
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'right', //在上方显示
                    textStyle: { //数值样式
                      color: "#d0fffd",
                      fontSize: _this.size.chartBottom.series_fontSize,
                      fontWeight: 400
                    }
                  },
                  color: "#33d4ff"
                }
            },
            data: _this.approvaled
          }
        ]
      })
    },
 
  },

    
}
</script> 
<style scoped lang="less"> 
 @media screen and (min-width: 1025px) {
  .chart-top{
    height: 1.9rem;
    padding: 0.2rem;
  }
  .infoTop{
      position: relative;
      width: 100%;
      height: 5rem;
  }
  .info-title{
      color: #58d2ff;
      font-size: 0.2rem;
  }
  .info-top-position{
      position: absolute;
      top: 0.22rem;
      left: 0.12rem;
  }
  .info-content{
      position: absolute;
      left: 0.12rem;
      top: 0.5rem;
      width: 100%;
  }
  .info-content{
      color: #58d2ff;
      text-align: left;
      .project-title-box{
          display: flex;
          margin-top: 0.18rem;
          line-height: 0.48rem;
          .project-log{
              width: 0.38rem;
              height: 0.21rem;
              border: 0.01rem solid #58d2ff;
              border-radius: 0.05rem;
              line-height: 0.21rem;
              text-align: center;
              font-size: .16rem;
          }
          .project-title{
              width: 3.6rem;
              //  height: 0.52rem;
              line-height: 0.24rem;
              padding: 0 0 0 0.2rem;
              font-size: 0.18rem;
              display:-webkit-box;
              overflow:hidden;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;  //显示两行
          }
      }
      .project-state{
          display: flex;
          justify-content: center;
          .state-pic-box{
              width: 0.5rem;
              height: 0.5rem;
              position: relative;
              img{
                position: absolute;
                  width:100%;
                  top: 0;
                  left: 0;
              }
          }
          .state-txt{
              line-height: 0.5rem;
              margin-top: 0.1rem;
          }
      }
      .statistical-info{
          margin-top: 0.1rem;
          .project-num{
              display:flex;
              .project-title{
                  width: 1.8rem;
                  line-height: 0.68rem;
                  font-size: 0.16rem;
                  color: #58D2FF;
              }
              .project-num-box{
                  width: 2rem;
                  display:flex;
                  .num{
                      width: 0.39rem;
                      height: 0.68rem;
                      background: url("../../../assets/screen/objectNum.png") no-repeat 0 0;
                      background-size: 100% 100%;
                      line-height: 0.68rem;
                      text-align: center;
                      font-size: 0.3rem;
                      color: #D0FFFD;
                      margin-right: 0.08rem;
                  }
              }
          }
          .default-quota-box{
              width: 3.6rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              line-height: 0.4rem;
              display: flex;
              .default-quota-txt{
                font-size:.16rem;
              }
              .default-quota-num{
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
          .involve-num-box{
              width: 3.6rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              display: flex;
              margin-top: 0.2rem;
              line-height: 0.4rem;
              .involve-leader-txt{
                font-size:.16rem;
              }
              .involve-group-txt{
                font-size:.16rem;
              }
          
              .involve-group-txt{
                  margin-left: 0.5rem;
              }
              .involve-leader-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
              .involve-group-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
          .involve-all-box{
              width: 1.8rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              display: flex;
              margin-top: 0.2rem;
              line-height: 0.4rem;
              .involve-all-txt{
                font-size:.16rem;
              }
              .involve-all-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
      }
  }
  .mb{
      margin-bottom: 0.1rem;
  }
  .txt-style{
      line-height: 0.68rem;
      text-align: center;
      font-size: 0.3rem;
      color: #D0FFFD;
  }
  .info-bottom{
      width: 100%;
      position: relative;
      margin-top: 0.1rem;
  
      .info-title{
          text-align: left;
      }
      .info-bottom-position{
          position: absolute;
          top: 0rem;
          left: 0.12rem;
      }
      .chart-column{
        position: absolute;
        top: 0.9rem;
        left: 0;
        // background: rgba(0,0,0,.5);
        width: 100%;
        height: 3.5rem;
      }
  }
  .scale-box{
      width: 100%;
      height: 3.5rem;
  }
  .lamp-status{
      width: 0.5rem;
      height: 0.5rem;
      text-align: center;
      // background-image: radial-gradient(rgba(255,0,0,.5) 5%,rgba(0,0,0,0)60%);
      // text-shadow: 0.05rem 0.05rem 0.1rem #FF0000;
      font-size: .16rem;
  }
}
// 小于1024
@media screen and (max-width: 1024px) {
  .chart-top{
    height: 1.5rem;
    padding-top: .4rem;
  }
  .infoTop{
      position: relative;
      width: 100%;
      height: 5rem;
  }
  .info-title{
      color: #58d2ff;
      font-size: 0.2rem;
  }
  .info-top-position{
      position: absolute;
      top: 0.22rem;
      left: 0.12rem;
  }
  .info-content{
      position: absolute;
      left: 0.12rem;
      top: 0.5rem;
      width: 100%;
  }
  .info-content{
      color: #58d2ff;
      text-align: left;
      .project-title-box{
          display: flex;
          margin-top: 0.18rem;
          .project-log{
              width: 0.6rem;
              height: 0.31rem;
              border: 0.01rem solid #58d2ff;
              border-radius: 0.05rem;
              line-height: 0.31rem;
              text-align: center;
              font-size: .16rem;
          }
          .project-title{
              width: 3.6rem;
              //  height: 0.52rem;
              line-height: 0.34rem;
              padding: 0 0 0 0.2rem;
              font-size: 0.18rem;
              display:-webkit-box;
              overflow:hidden;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;  //显示两行
          }
      }
      .project-state{
          display: flex;
          justify-content: center;
          .state-pic-box{
              width: 0.5rem;
              height: 0.5rem;
              position: relative;
              img{
                position: absolute;
                  width:100%;
                  top: 0;
                  left: 0;
              }
          }
          .state-txt{
              line-height: 0.5rem;
              margin-top: 0.1rem;
          }
      }
      .statistical-info{
          margin-top: 0.5rem;
          .project-num{
              display:flex;
              .project-title{
                  width: 2.5rem;
                  line-height: 0.48rem;
                  font-size: 0.16rem;
                  color: #58D2FF;
              }
              .project-num-box{
                  width: 2rem;
                  display:flex;
                  .num{
                      width: 0.39rem;
                      height: 0.48rem;
                      background: url("../../../assets/screen/objectNum.png") no-repeat 0 0;
                      background-size: 100% 100%;
                      line-height: 0.48rem;
                      text-align: center;
                      font-size: 0.3rem;
                      color: #D0FFFD;
                      margin-right: 0.08rem;
                  }
              }
          }
          .default-quota-box{
              width: 3.6rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              line-height: 0.4rem;
              display: flex;
              .default-quota-txt{
                font-size:.16rem;
              }
              .default-quota-num{
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
          .involve-num-box{
              width: 4.0rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              display: flex;
              margin-top: 0.2rem;
              line-height: 0.4rem;
              .involve-leader-txt{
                font-size:.16rem;
              }
              .involve-group-txt{
                font-size:.16rem;
              }
              .involve-group-txt{
                  margin-left: 0.5rem;
              }
              .involve-leader-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
              .involve-group-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
          .involve-all-box{
              width: 1.8rem;
              height: 0.4rem;
              border-bottom: 0.02rem solid #58D2FF;
              display: flex;
              margin-top: 0.2rem;
              line-height: 0.4rem;
              .involve-all-txt{
                font-size:.16rem;
              }
              .involve-all-num{
                  margin-left: 0.1rem;
                  color: #d0fffd;
                  font-size: 0.3rem;
              }
          }
      }
  }
  .mb{
      margin-bottom: 0.1rem;
  }
  .txt-style{
      line-height: 0.68rem;
      text-align: center;
      font-size: 0.3rem;
      color: #D0FFFD;
  }
  .info-bottom{
      width: 100%;
      position: relative;
      margin-top: 0.1rem;
  
      .info-title{
          text-align: left;
      }
      .info-bottom-position{
          position: absolute;
          top: 0rem;
          left: 0.12rem;
      }
      .chart-column{
          position: absolute;
          top: 0.7rem;
          left: 0;
          // background: rgba(0,0,0,.5);
          width: 100%;
          height: 3.5rem;
      }
  }
  .scale-box{
      width: 100%;
      height: 3.5rem;
  }
  .lamp-status{
      width: 0.5rem;
      height: 0.5rem;
      text-align: center;
      // background-image: radial-gradient(rgba(255,0,0,.5) 5%,rgba(0,0,0,0)60%);
      // text-shadow: 0.05rem 0.05rem 0.1rem #FF0000;
      font-size: .16rem;
  }
}
 </style> 