<template>
  <div class="main">
    <p class="title color pt25">今日数据</p>
    <div class="number">
      <div class="flex">
        <p v-if="page==1" class="color">所有工程总人数(人)</p>
        <p v-if="page==2" class="color">当前工程总人数(人)</p>
        <p class="numberBg center white">{{todayData.totalPerson}}</p>
      </div>
      <div class="flex">
        <p class="color">昨日考核人数(人)</p>
        <p class="numberBg center white">{{todayData.examimepPerson}}</p>
      </div>
      <div class="flex">
        <p class="color">今日新增人数(人)</p>
        <p class="numberBg center white">{{todayData.addPerson}}</p>
      </div>
      <div class="flex">
        <p class="color">今日离职人数(人)</p>
        <p class="numberBg center white">{{todayData.leavePerson}}</p>
      </div>
      <div class="flex">
        <p class="color">今日结算金额(元)</p>
        <p class="numberBg center white">{{todayData.daysettle ? todayData.daysettle : 0}}</p>
      </div>
    </div>
    <p class="title color pt5">考核情况统计</p>
    <div v-show="!placeHolderType" id="main"></div>
    <div  v-if="placeHolderType " class="placeholders">
      <div class="img-box">
        <img class="noImg" src="../../assets/screen/noData.png" alt="">
      </div>
    </div>
    <div class="line"></div>
    <div class="info">
      <div>
        <span class="point" style="background-color:#01f18e"></span>
        <span class="text">优秀</span>
      </div>
      <div>
        <span class="point" style="background-color:#33d4ff"></span>
        <span class="text">合格</span>
      </div>
      <div>
        <span class="point" style="background-color:#fcff00"></span>
        <span class="text">基本合格</span>
      </div>
      <div>
        <span class="point" style="background-color:#ed7048"></span>
        <span class="text">不合格</span>
      </div>
    </div>
  </div>
</template>
<script>
// 判断数字是否是整数
function isInteger(num){
  return num*1%1 === 0
}
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
import Vue from 'vue'
import { selectNowTotal, selectExamimeTotal, selectEngineeringExamimeTotal, selectNowProjectTotal } from "@/services/data"
export default {
  props: {
    engineeringId: {
      value: null
    },
    companyName: {
      value: ''
    }
  },
  data () {
    return {
      Vue,
      page:"1",
      todayData: {
        totalPerson: 0,
        examimepPerson: 0,
        addPerson: 0,
        leavePerson: 0,
        daysettle: 0
      }, // 今日数据
      attenceData: {}, // 考勤数据
      placeArr:[["管理服从度", '纪律表现', '安全文明施工', '质量', '效率'],['管理服从度 暂无数据', '纪律表现 暂无数据', '安全文明施工 暂无数据', '质量 暂无数据', '效率 暂无数据']],
      placeHolderType:false,
      s:{
        chartBottom:{
          barWidth:15,  //考核统计图粗细
          fontSize:10,
          rich_a:{      //考核 管理服从度等文字 居中
            align: "right",
            width: 25,
            fontSize:8,
            padding: [0, 0, 0, 0],
          },
          grid:{
            left: '1%',
            right: '5%',
            bottom:"15%",
            containLabel: true
          }
        },
      },
      l:{
        chartBottom:{
          barWidth:25,
          fontSize:18,
          rich_a:{      //考核 管理服从度等文字 居中
            align: "right",
            width: 40,
          },
          grid:{
            left: '1%',
            right: '5%',
            bottom:"15%",
            containLabel: true  //调整柱状图上下偏移量
          }
        },
      },
      xl:{
        chartBottom:{
          barWidth:35,
          fontSize:22,
          rich_a:{      //考核 管理服从度等文字 居中
            align: "right",
            width: 45,
          },
               grid:{
            left: '1%',
            right: '5%',
            bottom:"16%",
            containLabel: true  //调整柱状图上下偏移量
          }
        },
      },
      size:{
      },
      type:1,
      parames1:"",
      parames2:"",
      projectId:""
    }
  },
  created(){
    this.getWidth()
    this.type = sessionStorage.getItem('type')
    this.projectId = sessionStorage.getItem('projectId')

  },
  mounted(){
    this.createPlace()
  },
  methods:{
      getWidth(){
        this.windowX = document.documentElement.clientWidth
        console.log(this.windowX);
        if(this.windowX<=1024){
            this.size = this.s
        }else if(this.windowX > 1024 && this.windowX <= 1920){
            this.size = this.l
        }else if(this.windowX >1920){
            this.size = this.xl
        }
      },
    // 考勤情况统计柱状体
    getBar(attenceArr){
      // 基于准备好的dom，初始化echarts实例
      let dataArr1 = []
      let dataArr2 = []
      let dataArr3 = []
      let dataArr4 = []
      let dataArr5 = []
      attenceArr.forEach(item=>{
        let total = item.basicQualified*1 + item.qualified*1 + item.excellence*1 + item.belowStandard*1
        item.basicQualified = isInteger((item.basicQualified/total)*100) ? (item.basicQualified/total)*100 : ((item.basicQualified/total)*100).toFixed(2)
        item.qualified = isInteger((item.qualified/total)*100) ? (item.qualified/total)*100 : ((item.qualified/total)*100).toFixed(2)
        item.excellence = isInteger((item.excellence/total)*100) ? (item.excellence/total)*100 : ((item.excellence/total)*100).toFixed(2)
        item.belowStandard = isInteger((item.belowStandard/total)*100) ? (item.belowStandard/total)*100 : ((item.belowStandard/total)*100).toFixed(2)
        dataArr1.push(item.basicQualified)
        dataArr2.push(item.qualified)
        dataArr3.push(item.excellence)
        dataArr4.push(item.belowStandard)
      })
      dataArr5=this.placeHolderType?[0,0,0,0,0]:[]
      let myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      let option = {
        grid: this.size.chartBottom.grid,

        yAxis: {
          splitLine: {
            show: false
          },
          axisTick: {
            show: false,
          },
          axisLine:{
            show: false
          },
          axisLabel: {
            show: false
          }
        },
          xAxis: {
            type: 'category',
            data: this.placeHolderType?this.placeArr[1]:this.placeArr[0],
            z: 10,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false,
            },
            axisLine:{
              show: false,
            },
            axisLabel:{
              show: true,
              inside: true,
              color:"#333",
              rich: {
                a:this.size.chartBottom.rich_a
              },
              formatter: function(params) {
                let newParamsName = ""; // 最终拼接成的字符串
                let paramsNameNumber = params.length; // 实际标签的个数
                let provideNumber = 1; // 每行能显示的字的个数
                let rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (let p = 0; p < rowNumber; p++) {
                    let tempStr = ""; // 表示每一次截取的字符串
                    let start = p * provideNumber; // 开始截取的位置
                    let end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {

                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + "\n"
                    }
                    newParamsName += tempStr; // 最终拼成的字符串
                  }

                } else {
                    // 将旧标签的值赋给新标签
                    newParamsName = params;
                }
                //将最终的字符串返回
                // ["{a" + index + "|" + index + "}" + "  " + params]
                return "{a|"+newParamsName+ "}"
              }
            }
          },
          series: [
            {
              name: '管理服从度',
              barWidth: this.size.chartBottom.barWidth,
              barCategoryGap:'40%',
              type: 'bar',
              label: {
                show: true,
                // position: ['100%', '50%'],
                position: "insideRight",
                fontSize:10,
                formatter:function(params){
                  if(params.data > 0){
                    // return "{name| "+ params.data+"%}"
                    return ''
                  }else{
                    return ''
                  }
                },
                rich:{ //百分比文字颜色 大小
                  name: {
                    color: '#d0fffd',
                    fontSize: this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              data: []
            },
            {
              name: '纪律表现',
              type: 'bar',
              stack: 'total',
              barWidth: this.size.chartBottom.barWidth,
              barCategoryGap:'40%',
              // fontSize: this.size.chartBottom.fontSize,
              label: {
                show: true,
                // position: ['100%', '50%'],
                position: "insideRight",
                textStyle: {
                  color: "#fff",
                },
                formatter:function(params){
                  if(params.data > 0){
                    // return ''
                    return "{name| "+ params.data+"%}"
                  }else{
                    return ''
                  }
                },
                rich:{
                  name: {
                    color: '#d0fffd',
                    fontSize: this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: '#fcff00'
                }
              },
              data: dataArr1
            },
            {
              name: '安全文明施工',
              type: 'bar',
              stack: 'total',
              barWidth: this.size.chartBottom.barWidth,
              label: {
                show: true,
                // position: ['100%', '50%'],
                position: "insideRight",
                textStyle: {
                  color: "#000",
                  // fontSize: this.size.chartBottom.fontSize,
                },
                formatter:function(params){
                  if(params.data > 0){
                    return "{name| "+ params.data+"%}"
                    //  return ''
                  }else{
                    return ''
                  }
                },
                rich:{ //百分比文字颜色 大小
                  name: {
                    color: '#d0fffd',
                    fontSize:this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: '#33d4ff'
                }
              },
              data: dataArr2
            },
            {
              name: '质量',
              barWidth: this.size.chartBottom.barWidth,
              barCategoryGap:'50%',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                // position: ['100%', '50%'],
                position: "insideRight",
                // fontSize:this.size.chartBottom.fontSize,
                formatter:function(params){
                  if(params.data > 0){
                    return "{name| "+ params.data+"%}"
                    // return ''
                  }else{
                    return ''
                  }
                },
                rich:{
                  name: {
                    color: '#d0fffd',
                    fontSize: this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: '#01f18e'
                }
              },
              data: dataArr3
            },
            {
              name: '效率',
              barWidth: this.size.chartBottom.barWidth,
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                // position: ['50%', '50%'],
                position: "insideRight",
                // fontSize:this.size.chartBottom.fontSize,
                formatter:function(params){
                  if(params.data > 0){
                    return "{name| "+ params.data+"%}"
                    // return ''
                  }else{
                    return ''
                  }
                },
                rich:{
                  name: {
                    color: '#d0fffd',
                    fontSize: this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: '#ed7048'
                }
              },
              data: dataArr4
            },
             {
              name: '暂无数据',
              barWidth: this.size.chartBottom.barWidth,
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
                // position: ['0%', '0%'],
                position: "insideRight",
                formatter:function(params){
                  console.log(params,"params")
                  if(params.data > 0){
                    return "{name| "+ params.data+"%}"
                    // return ''
                  }else{
                    return ''
                  }
                },
                rich:{
                  name: {
                    color: '#d0fffd',
                    fontSize:this.size.chartBottom.fontSize,
                    fontWeight: 400,
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: "rgba(0,0,0,0.4)"
                }
              },
              data: dataArr5
            },
          ]
      };
      myChart.setOption(option);
    },
    // 获取首页今日数据
    getTodayData(){
      this.page = Vue.ls.get("page")
      this.todayData = {
        totalPerson: 0,
        examimepPerson: 0,
        addPerson: 0,
        leavePerson: 0,
        daysettle: 0
      }
      selectNowTotal().then(res=>{
        if(res.code == 200 && res.data){
          this.todayData = res.data
        }else{
          this.todayData = {
            totalPerson: 0,
            examimepPerson: 0,
            addPerson: 0,
            leavePerson: 0,
            daysettle: 0
          }
        }
      })
      
    },
    // 获取内页今日数据
    getTodayData1(id){
      this.page = Vue.ls.get("page")
      this.todayData = {
        totalPerson: 0,
        examimepPerson: 0,
        addPerson: 0,
        leavePerson: 0,
        daysettle: 0
      }
      this.engineeringId = id
      if(this.type == 0){
        this.parames1= {
          projectId: this.projectId
        }
      }else {
        this.parames1 = {
          engineeringId: this.engineeringId ? this.engineeringId : id
        }
      }
      selectNowProjectTotal({
        ...this.parames1
      }).then(res=>{
        if(res.code == 200 && res.data){
          this.todayData = res.data
        }else{
          this.todayData =  {
            totalPerson: 0,
            examimepPerson: 0,
            addPerson: 0,
            leavePerson: 0,
            daysettle: 0
          }
        }
      })
    },
    // 获取首页考勤情况统计
    async getAttence(){
      console.log('首页考勤')
      let attenceArr = []
      let dataObj = {}
      await selectExamimeTotal({}).then(res=>{
        if(res.code == 200){
          // 获取管理相关数据
          if(!res.data) return 
          dataObj = res.data
        }
      })
      // true显示暂无数据
      JSON.stringify(dataObj) == "{}"?this.placeHolderType = true:this.placeHolderType = false
      let manage = {
        "basicQualified": dataObj.manageBase,
        "qualified": dataObj.manageQualified,
        "excellence": dataObj.manageFine,
        "belowStandard": dataObj.manageNo,
        "examineType": 0
      }
      attenceArr.push(manage)
      // 获取纪律相关数据
      let morality = {
        "basicQualified": dataObj.moralityBase,
        "qualified": dataObj.moralityQualified,
        "excellence": dataObj.moralityFine,
        "belowStandard": dataObj.moralityNo,
        "examineType": 1
      }
      attenceArr.push(morality)
      // 获取安全相关数据
      let safe = {
        "basicQualified": dataObj.safeBase,
        "qualified": dataObj.safeQualified,
        "excellence": dataObj.safeFine,
        "belowStandard": dataObj.safeNo,
        "examineType": 2
      }
      attenceArr.push(safe)
      // 获取质量相关数据
      let quality = {
        "basicQualified": dataObj.qualityBase,
        "qualified": dataObj.qualityQualified,
        "excellence": dataObj.qualityFine,
        "belowStandard": dataObj.qualityNo,
        "examineType": 0
      }
      attenceArr.push(quality)
      // 获取效率相关数据
      let efficient = {
        "basicQualified": dataObj.efficientBase,
        "qualified": dataObj.efficientQualified,
        "excellence": dataObj.efficientFine,
        "belowStandard": dataObj.efficientNo,
        "examineType": 0
      }
      attenceArr.push(efficient)
      if(attenceArr.length > 0){
        this.getBar(attenceArr)
      }
    },
    // 获取内页考勤情况统计 ovee
    async getAttence1(id){
      this.engineeringId = id
      let attenceArr = []
      let dataObj = {}
      if(this.type == 0){
        this.parames2 = {
          projectId: this.projectId
        }
      }else {
        this.parames2 = {
          engineeringId: this.engineeringId ? this.engineeringId : id
        }
      }
      await selectEngineeringExamimeTotal({
        ...this.parames2
      }).then(res=>{
        if(res.code == 200){
          // 获取管理相关数据
          if(!res.data) return
          dataObj = res.data
        }
      })
      // true显示暂无数据
      JSON.stringify(dataObj) == "{}"?this.placeHolderType = true:this.placeHolderType = false
      let manage = {
        "basicQualified": dataObj.manageBase,
        "qualified": dataObj.manageQualified,
        "excellence": dataObj.manageFine,
        "belowStandard": dataObj.manageNo,
        "examineType": 0
      }
      attenceArr.push(manage)
      // 获取纪律相关数据
      let morality = {
        "basicQualified": dataObj.moralityBase,
        "qualified": dataObj.moralityQualified,
        "excellence": dataObj.moralityFine,
        "belowStandard": dataObj.moralityNo,
        "examineType": 1
      }
      attenceArr.push(morality)
      // 获取安全相关数据
      let safe = {
        "basicQualified": dataObj.safeBase,
        "qualified": dataObj.safeQualified,
        "excellence": dataObj.safeFine,
        "belowStandard": dataObj.safeNo,
        "examineType": 2
      }
      attenceArr.push(safe)
      // 获取质量相关数据
      let quality = {
        "basicQualified": dataObj.qualityBase,
        "qualified": dataObj.qualityQualified,
        "excellence": dataObj.qualityFine,
        "belowStandard": dataObj.qualityNo,
        "examineType": 0
      }
      attenceArr.push(quality)
      // 获取效率相关数据
      let efficient = {
        "basicQualified": dataObj.efficientBase,
        "qualified": dataObj.efficientQualified,
        "excellence": dataObj.efficientFine,
        "belowStandard": dataObj.efficientNo,
        "examineType": 0
      }
      attenceArr.push(efficient)
      if(attenceArr.length > 0){
        this.getBar(attenceArr)
      }
    },
    createPlace(){
      this.placeArr.forEach((item,index)=>{
        item.forEach((im,ix)=>{
          let txtStyle = {
            value:im,
            textStyle: {
                fontSize: 12,
                color: index==0?'#222b2b':'#d0fffd'
            }
          }
         this.placeArr[index][ix]=txtStyle
        })
      })
    }
  },

};
</script>
<style scoped lang="less">
p{
  margin: 0 !important;
}
.pt25{
  padding-top: .25rem;
}
.pt5{
  padding-top: .05rem;
}
.main{
  position: relative;
  padding-left: .25rem;
}
.number{
  margin-top: .2rem;
}
.color {
  color: #58d2ff;
  font-size: .18rem;
}
.white {
  color: #d0fffd;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: .2rem;
}
.flex p:nth-child(1){
    width: 1.6rem;
    text-align: left;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: .2rem;
  text-align: left;
}
.numberBg {
  width: 2.25rem;
  height: .68rem;
  background: url("../../assets/screen/numberBg.png") center center no-repeat;
  background-size: 100% 100%;
  font-size: .3rem;
  font-weight: bold;
}
#main{
  position: relative;
  width: 4.5rem;
  height: 4rem;
  top: -.2rem;
  left: -0.3rem;
}
.placeholders{
  position: relative;
  width: 4.5rem;
  height: 4rem;
  margin: 0 auto;

  .img-box{
    margin: 0 auto;
    padding-top: 0.5rem;
    width: 2.5rem;
    height: 2rem;;
    .noImg{
      width: 100%;
      height: 100%;
    }
  }

}
.info{
  position: absolute;
  bottom: .2rem;
  width: 3.4rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  left: .55rem;
  .point{
    display: inline-block;
    border-radius: .02rem;
    width: .13rem;
    height: .13rem;
  }
  .text{
    font-size: .16rem;
    color: #58D2FF;
    margin-left: .05rem;
  }
}
.line{
  position: relative;
  top: -.8rem;
  left: .2rem;
  width: 3.6rem;
  height: .01rem;
  background-color: #58D2FF;
  text-align: center;
}
</style>