<template>
  <div class="main">
    <p class="flex" style="height:.3rem">
      <span class="title color">项目统计数据</span>
      <img v-if="isFirst" @click="getProject()" class="all" src="../../assets/screen/all.png" alt="">
    </p>
    <div v-if="isFirst" class="tableBox">
      <ul class="flex tabletitle">
        <li>序号</li>
        <li>大合同项目名称</li>
        <li>拖欠总金额(元)</li>
        <li>涉及领队人数</li>
        <li>涉及班组数</li>
        <li>涉及总人数</li>
        <li>状态</li>
      </ul>
      <div v-if="tableData.length > 0" class="tableScroll swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in tableData" :key="index">
            <ul class="content flex">
              <li>{{index > 7 ? (index + 1) : '0'+(index + 1)}}</li>
              <!--  -->
              <li :class="item.ename.length > 11 ? 'enameLength': 'hidden'" @click="goCurrent(item.engineeringId)" :title="item.ename" :data-id="item.engineeringId" :data-type="5">{{item.ename}}</li>
              <li>{{item.arrearsMoney}}</li>
              <li :data-id="item.engineeringId" :data-type="1" @click="goSecond(item.engineeringId, 1, item.ename)" :data-name="item.ename">{{item.teamNum}}</li>
              <li :data-id="item.engineeringId" :data-type="3" @click="goSecond(item.engineeringId, 3, item.ename)" :data-name="item.ename">{{item.groupNum}}</li>
              <li :data-id="item.engineeringId" :data-type="2" @click="goSecond(item.engineeringId, 2, item.ename)" :data-name="item.ename">{{item.workerNum}}</li>
              <li :data-id="item.subReqStatus" :data-type="4" :data-status="item.subReqStatus" @click="getProject(item.subReqStatus)">
                <div v-if="item.subReqStatus == 0" class="lamp-box"> 
                  <img class="icon"  src="@/assets/screen/icon4.png" alt="">
                  <div style="color:#ff0000">红灯</div>
                </div>
                <div  v-else-if="item.subReqStatus == 2" class="lamp-box">
                  <img class="icon" src="@/assets/screen/icon1.png" alt="">
                  <div style="color:#ffe900">黄灯</div>
                </div>
                <div  v-else-if="item.subReqStatus == 3" class="lamp-box">
                  <img class="icon" x src="@/assets/screen/icon2.png" alt="">
                  <div style="color:#7aff00">正常</div>
                </div>
                <div v-else-if="item.subReqStatus == 1" class="lamp-box">
                 <img class="icon"  src="@/assets/screen/icon3.png" alt="">
                 <div style="color:#ff7f00">半拖欠</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else class="tableScroll">
        <img class="noImg" src="../../assets/screen/noData.png" alt="">
      </div>
      <div class="list-footer">
          <div class="flex-list-footer">
            <div class="state-list state1">绿灯：正常项目</div>
            <div class="state-list state2">黄灯：拖欠劳务领队</div>
            <div class="state-list state3">橙灯：半拖欠项目</div>
            <div class="state-list state4">红灯：拖欠工人项目</div>
          </div>
        </div>
    </div>
    <div v-if="!isFirst" class="secondBox">
      <ul class="flex tabletitle">
        <li>序号</li>
        <li>项目名称</li>
        <li>劳务公司</li>
        <li>施工总人数</li>
        <li>班组数量</li>
        <li>昨日施工人数</li>
        <li>开竣工日期</li>
      </ul>
      <div v-if="secondData.length > 0" class="tableScroll swiper-container1">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in secondData" :key="index">
            <ul class="content flex">
              <li>{{index > 8 ? (index + 1) : '0'+(index + 1)}}</li>
              <!-- @click="goSecond(item.engineeringId,2,item.reqName)" -->
              <li :class="item.reqName.length > 11 ? 'enameLength': 'hidden'" :title="item.reqName"   :data-id="item.engineeringId" :data-type="5">{{item.reqName}}</li>
              <!-- <li :class="item.comapnyName.length > 11 ? 'enameLength': 'hidden'" :title="item.comapnyName">{{item.comapnyName ? item.comapnyName : "——"}}</li> -->
              <li :class="item.comapnyName.length > 11 ? 'enameLength': 'hidden'" v-if="item.comapnyName" :title="item.comapnyName">{{item.comapnyName}}</li>
              <li class="hidden" v-else :title="item.comapnyName">—</li>
              <li>{{item.totalPerson}}</li>
              <li>{{item.totalGroup}}</li>
              <li>{{item.yesterdayPerson}}</li>
              <!-- class="ellipsis" -->
              <li class="enameLength1" :title="item.startDate+'-'+item.endDate">
                {{item.startDate}}-{{item.endDate}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else class="tableScroll">
        <img class="noImg" src="../../assets/screen/noData.png" alt="">
      </div>
    </div>
    <div class="chart-bottom ">
      <div class=" color flex">
        <div class="title">
          项目工人统计
        </div>
        <div class="title mr-more cursor-pointer" v-if="!isFirst" @click="openMore">查看更多</div>
      </div>
      
      <div class="chart-box">
        <div id='echartLine' style="width:8.5rem; height:2.2rem;">
        </div>
        <div class="echartLine-txt">
          每日人数统计
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import { selectProjectData, selectProjectDemandTotal } from "@/services/data"
import { projectWorkerTotal, selectProjectPersonTotal } from "@/services/index"

export default {
  props: {
    isFirst: {
      value:true
    }, // 是否是首页
    engineeringId: {
      value: null
    }, //工程id
    companyName: {
      type: String
    }, // 公司名称
    companyLogo: {
      type: String
    }, // 公司logo
  },

  data () {
    return {
      tableData: [], // 首页项目统计数据表格数据
      secondData: [], // 内页项目统计数据表格数据`````
      type:1,
      parames1:"",
      parames2:"",
      projectId:"",
      flag:true,
    }
  },
  created(){
    this.type = sessionStorage.getItem('type')
    this.projectId = sessionStorage.getItem('projectId')
  },
  methods:{
    // 生成工人统计折线图
    createEchart(dateArr, valueArr){
      // console.log(dateArr, valueArr)
      let _this = this
      _this.charts = _this.$echarts.init(document.getElementById('echartLine'));
      _this.charts.setOption({
        tooltip: {},
        grid: {
            top: '8%',
            left: '1%',
            right: '1%',
            bottom: '8%',
            containLabel: true,
        },
        legend: {
            itemGap: 50,
            data: ['人员出入总数'],
            textStyle: {
                color: '#f9f9f9',
                borderColor: '#fff'
            },
        },
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisLine: { //坐标轴轴线相关设置。数学上的x轴
                show: true,
                lineStyle: {
                    color: '#2877B9',
                    width: 2
                },
                symbolSize :[10,15]
            },
            axisLabel: { //坐标轴刻度标签的相关设置
                textStyle: {
                    color: '#d0fffd',
                    margin: 15,
                },
            },
            axisTick: {
                show: false,
            },
            data: dateArr
        }],
        yAxis: [{
            type: 'value',
            min: 0,
            // max: 140,
            minInterval: 1,
            splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(0,0,0,0)',
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#2877B9',
                width: 2
              },
            },
            axisLabel: {
              margin: 20,
              textStyle: {
                color: '#d1e6eb',
              },
            },
            axisTick: {
              show: false,
            },
        }],
        series: [{
            name: '项目工人统计',
            type: 'line',
            smooth: true, //是否平滑曲线显示
            // symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 6,
            lineStyle: {
              normal: {
                  color: "#33d4ff", // 线条颜色
              },
              borderColor: '#f0f'
            },
            label: {
              show: false,
            },
     
            itemStyle: {
              normal: {
                  color: "#33d4ff",
              }
            },
            tooltip: {
              show:true,
              backgroundColor:"rgba(0,0,0,0)",
              textStyle:{
                color:"#fff",
              },
              position:"top",
              padding:[5],
              formatter:function(params){
                console.log(params,'params',params.value,params.name)
                return `在岗人数: ${params.value}`
              }
            },
            areaStyle: { //区域填充样式
                normal: {
                    //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                    color:  new _this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(51,212,255,0.5)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(0,0,0, 0)'
                        }
                    ], false),
                    // color:"rgba(20,20,20,.5)",
                    shadowColor: 'rgba(53,142,215, 0.9)', //阴影颜色
                    shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                }
            },
            data: valueArr
          }
        ]
      })
    },
    // 获取项目统计数据
    getProject(type){
      let _this = this
      _this.tableData = []
      selectProjectData({
        subReqStatus: type ? type : null
      }).then(res=>{
        if(res.code == 200 && res.data){
          // res.data = res.data.concat(res.data)
          _this.tableData = res.data
          _this.$nextTick(()=>{
            if (res.data.length > 7) {
             let mySwiper = new Swiper('.swiper-container', {
                direction: 'vertical',
                speed: 3000, // 速度
                mousewheel: true, // 鼠标滚轮控制
                autoplay: {
                  delay: 3000, //3秒切换一次
                  disableOnInteraction: false
                },
                loop:true,
                slidesPerView: 7, // 每页显示几个slide
                on:{
                  click(e){
                    let id = e.target.dataset.id
                    let type = e.target.dataset.type
                    let name = e.target.dataset.name
                    let status = e.target.dataset.status
                    if(type == 1 || type == 2 || type == 3){
                      _this.goSecond(id,type,name)
                    }else if(type == 4){
                      _this.getProject(status)
                    }else if(type == 5){
                      _this.goCurrent(id)
                    }
                  }
                },
              })
              //鼠标覆盖停止自动切换
              mySwiper.el.onmouseover = function(){
                mySwiper.autoplay.stop();
              }
              //鼠标离开开始自动切换
              mySwiper.el.onmouseout = function(){
                mySwiper.autoplay.start();
              }
            }
          })
        }else {
          this.tableData = []
        }
      })
    },
    // 获取项目统计数据 ovee
    getProject1(id){
      this.secondData = []
      this.engineeringId = id
      if(this.type == 0){
        this.parames1 = {
          projectId: this.projectId
        }
      }else {
        this.parames1 = {
          engineeringId: this.engineeringId ? this.engineeringId : id
        }
      }
      selectProjectDemandTotal({
        ...this.parames1
      }).then((res)=>{
        if(res.code == 200 && res.data){
          this.secondData = res.data
          res.data.forEach((item)=>{
            item.startDate = item.startDate.replace(/-/g,'/')
            item.endDate = item.endDate.replace(/-/g,'/')
          })
          this.$nextTick(()=>{
            if (res.data.length > 8) {
             let mySwiper1 = new Swiper('.swiper-container1', {
                direction: 'vertical',
                speed: 3000, // 速度
                mousewheel: true, // 鼠标滚轮控制
                autoplay: {
                  delay: 3000, //3秒切换一次
                  disableOnInteraction: false
                },
                loop:true,
                slidesPerView: 8, // 每页显示几个slide
              })
              //鼠标覆盖停止自动切换
              mySwiper1.el.onmouseover = function(){
                mySwiper1.autoplay.stop();
              }
              //鼠标离开开始自动切换
              mySwiper1.el.onmouseout = function(){
                mySwiper1.autoplay.start();
              }
            }
          })
        }else {
          this.tableData = []
        }
      })
    },
    // 点击项目名称跳转至详情页面
    goCurrent(id){
      // 一秒内执行一次
      if (!this.flag) return
      sessionStorage.setItem('engineeringSecondId', id)
      this.$emit('getId',id)
      this.flag = false; 
      setTimeout(() => {
				this.flag = true  
			}, 1000) 
    },
    // 跳转至项目统计页面
    goSecond(id,type,name){
      this.$router.push({
        path: "/table",
        query: { 
          id: id ? id : sessionStorage.getItem('engineeringSecondId'),
          type: type,
          name: name,
          companyName: this.companyName,
          companyLogo: this.companyLogo
        }
      })
    },
    // 获取首页项目工人统计数据
    async getWorkData(){
      let dateArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      let valueArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      // 首页数据
      await projectWorkerTotal().then(res=>{
        if(res.code == 200 && res.data){
          dateArr = []
          valueArr = []
          res.data.forEach(item=>{
            valueArr.push(item.yesterdayPerson)
            dateArr.push(item.datetime.split("-")[2])
          })
          console.log(res.data)
          // this.createEchart(dateArr, valueArr)
        }else{
          dateArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          valueArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        }
        // 生成echarts图表
        this.createEchart(dateArr, valueArr)
      })
    },
    // 获取内页项目工人统计数据
    async getWorkData1(id){
      let dateArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      let valueArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      this.engineeringId = id
      // 内页数据 ovee
      if(this.type == 0){
        this.parames2 = {
          projectId: this.projectId
        }
      }else {
        this.parames2 = {
          engineeringId: this.engineeringId ? this.engineeringId : id
        }
      }

      await selectProjectPersonTotal({
        ...this.parames2
      }).then(res=>{
        dateArr = []
        valueArr = []
        if(res.code == 200 && res.data){
          res.data.forEach(item=>{
            valueArr.push(item.yesterdayPerson)
            dateArr.push(item.datetime.split("-")[2])
          })
        }else{
          dateArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
          valueArr = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        }
        // 生成echarts图表
        this.createEchart(dateArr, valueArr)
      })
    },

    //打开 施工试试检测大屏
    openMore(){
      this.$router.push({
        path:"/construction"
      })
    },
  }
};
</script>
<style scoped lang="less">
.cursor-pointer{
  cursor:pointer;
}
// @import "../../../node_modules/swiper/dist/css/swiper.css";
p{
  margin: 0 !important;
}
li {
  list-style: none;
  cursor: pointer
}
.all {
  width: 1.05rem !important;
  height: .35rem !important;
  margin: -5px 24px 0 0;
  cursor: pointer;
}
.flex{
  display: flex;
  justify-content: space-between;
}
.main{
  position: relative;
  padding-top: .64rem;
}
.title {
  font-size: .2rem;
  text-align: left;
  padding-left: .30rem;
}
.mr-more{
  margin-right: 0.5rem;
}
.noImg{
  width: 2.26rem !important;
  height: 2.37rem !important;
  margin-top: 1rem;
}
.tableBox{
  width: 8.4rem;
  li:nth-child(1){
    width: .6rem;
  }
  li:nth-child(2){
    width: 1.6rem;
  }
  li:nth-child(3){
    width: 1.2rem;
  }
  li:nth-child(4){
    width: 1.3rem;
  }
  li:nth-child(5){
    width: 1.15rem;
  }
  li:nth-child(6){
    width: 1.15rem;
  }
  li:nth-child(7){
    width: 1rem;
  }
}
.secondBox{
  width: 8.4rem;
  li:nth-child(1){
    width: .4rem;
  }
  li:nth-child(2){
    width: 1.8rem;
  }
  li:nth-child(3){
    width: 1.7rem;
  }
  li:nth-child(4){
    width: 0.9rem;
  }
  li:nth-child(5){
    width: .8rem;
  }
  li:nth-child(6){
    width: 1.3rem;
  }
  li:nth-child(7){
    width: 1.5rem;
  }
}
 // 大于1024
 @media screen and (min-width: 1025px) {
    .tabletitle {
    width: 8rem;
    margin: .2rem auto 0 auto;
    padding-bottom: .06rem;

  }
 }
 // 小于1024
 @media screen and (max-width: 1024px) {
    .tabletitle {
      width: 8rem;
      margin: 0.2rem auto 0 auto;
      padding-bottom: .01rem;
      li{
        color:#D0FFFD;
        font-size: .12rem;
        line-height: 0.25rem;
      }
      .dp-6{
        width: 1.2rem;
      }
    }
 }

.tableScroll{
  // height:4.96rem;
  height:4.34rem;
  overflow: hidden;
  position: relative;

}
.list-footer{
  // position: absolute;
  // bottom:-0.62rem;
  // left: 0;
  font-size: .16rem;
  color: #ffffff;
  width: 8rem;
  height: 0.62rem;
  line-height: 0.62rem;
  padding: 0 0.2rem 0 0.18rem;
  margin: 0 auto 0 0.06rem;
}
.state1{
  color: #7aff00;
}
.state2{
  color: #ffe900 ;
}
.state3{
  color: #ff7f00;
}
.state4 {
  color: #ff0000;
}

.flex-list-footer{
  display: flex;

}
.state-list{
  width: 25%;
}
.swiper-slide p,.swiper-slide{
  height: .62rem;
  line-height: .62rem;
  color: #fff;
}

.content{
  height: .62rem;
  // line-height: .62rem;
  // width: 8.00rem;
  padding: 0 .2rem 0 .18rem;
  margin: 0 auto 0 .06rem;
  align-items: center;
  li{
    font-size: .14rem; 
    color: #D0FFFD;
  }
  img{
    width: .5rem;
    height: .5rem;
  }
}
.content:hover{
  background: url('../../assets/screen/tableHover.png') center center no-repeat;
  background-size: 8.32rem .64rem;
}
.color {
  color: #58d2ff;
}
.echartLine-txt{
  color:#58d2ff;
  font-size: .18rem;
}
.chart-bottom{
  position: absolute;
  top: 6.56rem;
}
</style>
<style lang="less">
.myTable{
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -ms-overflow-style: none;
  thead tr {
    color: #58D2FF;
  }
  .vxe-table--header-wrapper{
    background-color: transparent !important;
  }
  .vxe-table--body-wrapper{
    background-color: transparent !important;
    height: 4.96rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .vxe-body--row{
    height: .62rem;
    line-height: .62rem;
    .col--last{
      padding: 0
    }
    .vxe-cell{
      color:#D0FFFD;
      font-size: .14rem;
    }
  }
  .icon{
    position: relative;
    width: .5rem;
    height: .5rem;
  }
  tbody tr:hover{
    background: url('../../assets/screen/tableHover.png') center center no-repeat;
    background-size: 8.32rem .64rem;
  }
}
.lamp-box{
  display: flex;
}
.enameLength {
  position: relative;
  line-height: .24rem;
  height: .48rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: .07rem;
  text-align: left;

}
.enameLength1 {
  position: relative;
  line-height: .24rem;
  height: .48rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: .07rem;
  text-align: center;

}
.hidden{
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
}
.ellipsis{
display:-webkit-box;
	overflow:hidden;
	-webkit-box-orient:vertical;
	-webkit-line-clamp:2;  //显示两行
}

</style>